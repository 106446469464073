import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, View, Switch, ActivityIndicator, TouchableOpacity, Alert, Platform } from 'react-native';
import Colors from '../constants/Colors';
import Strings from '../constants/Strings';
import SettingsAPI from '../services/SettingsAPI';
import TextField from '../tools/TextField';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import Svg, { Path } from "react-native-svg";
import Layout from '../constants/Layout';
import Icon from '../constants/Icon';
import AlertingService from '../services/AlertingService';

export default function SettingsScreen(props) {
    const [loaded, setLoaded] = useState(false);
    const [streams, setStreams] = useState(null);
    const [tabs, setTabs] = useState(null);
    const [whatsapp, setWhatsapp] = useState(null);
    const [limits, setLimits] = useState(null);
    const [layout, setLayout] = useState(null);
    const [minVersion, setMinVersion] = useState(null);

    const updateStreams = (stream, url) => {
        setStreams(prev => ({
            ...prev,
            [stream]: url
        }));
    }
    const updateTab = tab => {
        setTabs(prev => ({
            ...prev,
            [tab]: !prev[tab]
        }));
    }
    const updateLimits = (type, limit) => {
        setLimits(prev => ({
            ...prev,
            [type]: limit
        }));
    }
    const updateLayout = (type) => {
        setLayout(prev => ({
            ...prev,
            [type]: !prev[type]
        }));
    }
    const updateMinVersion = (type, version) => {
        setMinVersion(prev => ({
            ...prev,
            [type]: version
        }));
    }

    const reallySave = async () => {
        SettingsAPI.Settings.whatsapp = whatsapp;
        for (var l in limits) {
            try {
                SettingsAPI.Settings.api.limits[l] = parseInt(limits[l]);
            } catch (error) {
                SettingsAPI.Settings.api.limits[l] = 20;
            }
        }
        SettingsAPI.Settings.streams = streams;
        SettingsAPI.Settings.tabs = tabs;
        SettingsAPI.Settings.layout.items = layout;
        SettingsAPI.Settings.minVersion = minVersion;
        await SettingsAPI.Save();
        AlertingService.Alert(Strings.Settings.AlertTitle, Strings.Settings.Successful, () => {props.navigation.navigate("Home")})
    }
    const save = () => {
        AlertingService.Prompt(Strings.Settings.AlertTitle, Strings.Settings.AlertDesc, reallySave)
    }

    useEffect(() => {
        SettingsAPI.Get("megamix").then(settings => {
            setStreams({
                audio: settings.streams.audio,
                megamix: settings.streams.megamix,
                video: settings.streams.video
            });
            setTabs({
                home: settings.tabs.home,
                chat: settings.tabs.chat,
                shows: settings.tabs.shows,
                whatsapp: settings.tabs.whatsapp,
                live: settings.tabs.live
            });
            setWhatsapp(settings.whatsapp);
            setLimits({
                general: settings.api.limits.general.toString(),
                chat: settings.api.limits.chat.toString(),
                shows: settings.api.limits.shows.toString(),
                episodes: settings.api.limits.episodes.toString(),
                fullShows: settings.api.limits.fullShows.toString(),
                authors: settings.api.limits.authors.toString()
            });
            setLayout(settings.layout.items);
            setMinVersion(settings.minVersion);
        });
    }, []);

    useEffect(() => {
        if (streams && tabs && whatsapp && limits && layout && minVersion)
            setLoaded(true);
    }, [streams, tabs, whatsapp, limits, layout, minVersion])

    if (loaded) {
        return (
            <View style={styles.container}>
                <ScrollView style={styles.container}>
                    <View style={styles.catHolder}>
                        <TextField text={Strings.Settings.Tabs} weight={600} style={styles.catTitle}></TextField>
                        <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                            <TouchableOpacity onPress={() => {updateTab("home")}} style={{padding:Layout.Settings.MarginSmall,alignItems:'center',justifyContent:'center',marginStart:Layout.Settings.MarginNormal}}>
                                <Svg width={20} height={20} viewBox="0 0 70 70" style={{marginBottom:Layout.Settings.MarginSmall}}>
                                    <Path d={Icon.SVGs.Home} fill={tabs.home?Colors.General.Link:Colors.General.Text1} />
                                </Svg>
                                <TextField text={Strings.Settings.TabHome} style={{fontSize:11,color:tabs.home?Colors.General.Link:Colors.General.Text1}}></TextField>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {updateTab("chat")}} style={{padding:Layout.Settings.MarginSmall,alignItems:'center',justifyContent:'center',marginStart:Layout.Settings.MarginNormal}}>
                                <Svg width={20} height={20} viewBox="0 0 70 70" style={{marginBottom:Layout.Settings.MarginSmall}}>
                                    <Path d={Icon.SVGs.Chat} fill={tabs.chat?Colors.General.Link:Colors.General.Text1} />
                                </Svg>
                                <TextField text={Strings.Settings.TabChat} style={{fontSize:11,color:tabs.chat?Colors.General.Link:Colors.General.Text1}}></TextField>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {updateTab("live")}} style={{padding:Layout.Settings.MarginSmall,alignItems:'center',justifyContent:'center',marginStart:Layout.Settings.MarginNormal}}>
                                <TextField text={Strings.General.Live} weight={800} style={{fontSize:20,color:tabs.live?Colors.General.Link:Colors.General.Text1}}></TextField>
                                <TextField text={Strings.Settings.TabLive} style={{fontSize:11,color:tabs.live?Colors.General.Link:Colors.General.Text1}}></TextField>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {updateTab("whatsapp")}} style={{padding:Layout.Settings.MarginSmall,alignItems:'center',justifyContent:'center',marginStart:Layout.Settings.MarginNormal}}>
                                <Icon type={Icon.Type.Whatsapp} style={{color: tabs.whatsapp?Colors.General.Link:Colors.General.Text1, fontSize:24}}></Icon>
                                <TextField text={Strings.Settings.TabWhatsapp} style={{fontSize:11,color:tabs.whatsapp?Colors.General.Link:Colors.General.Text1}}></TextField>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {updateTab("shows")}} style={{padding:Layout.Settings.MarginSmall,alignItems:'center',justifyContent:'center',marginStart:Layout.Settings.MarginNormal}}>
                                <Svg width={20} height={20} viewBox="0 0 70 70" style={{marginBottom:Layout.Settings.MarginSmall}}>
                                    <Path d={Icon.SVGs.Shows} fill={tabs.shows?Colors.General.Link:Colors.General.Text1} />
                                </Svg>
                                <TextField text={Strings.Settings.TabShows} style={{fontSize:11,color:tabs.shows?Colors.General.Link:Colors.General.Text1}}></TextField>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.catHolder}>
                        <TextField text={Strings.Settings.Streams} weight={600} style={styles.catTitle}></TextField>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Audio} style={styles.settingName}></TextField>
                            <InputField ltr value={streams.audio} onChangeText={text=>updateStreams("audio", text)} placeholder={Strings.Settings.Audio}></InputField>
                        </View>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Megamix} style={styles.settingName}></TextField>
                            <InputField ltr value={streams.megamix} onChangeText={text=>updateStreams("megamix", text)} placeholder={Strings.Settings.Megamix}></InputField>
                        </View>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Video} style={styles.settingName}></TextField>
                            <InputField ltr value={streams.video} onChangeText={text=>updateStreams("video", text)} placeholder={Strings.Settings.Video}></InputField>
                        </View>
                    </View>

                    <View style={styles.catHolder}>
                        <TextField text={Strings.Settings.Whatsapp} weight={600} style={styles.catTitle}></TextField>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Whatsapp} style={styles.settingName}></TextField>
                            <InputField ltr keyboardType="number-pad" value={whatsapp} onChangeText={text=>setWhatsapp(text)} placeholder={Strings.Settings.Whatsapp}></InputField>
                        </View>
                    </View>

                    <View style={styles.catHolder}>
                        <TextField text={Strings.Settings.ItemsSettings} weight={600} style={styles.catTitle}></TextField>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Chat} style={styles.settingName}></TextField>
                            <InputField ltr keyboardType="number-pad" value={limits.chat} onChangeText={text=>updateLimits("chat", text)} placeholder={Strings.Settings.Chat}></InputField>
                        </View>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.Items} style={styles.settingName}></TextField>
                            <InputField ltr keyboardType="number-pad" value={limits.episodes} onChangeText={text=>updateLimits("episodes", text)} placeholder={Strings.Settings.Items}></InputField>
                        </View>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.FullShows} style={styles.settingName}></TextField>
                            <InputField ltr keyboardType="number-pad" value={limits.fullShows} onChangeText={text=>updateLimits("fullShows", text)} placeholder={Strings.Settings.FullShows}></InputField>
                        </View>

                        <View style={styles.settingHolderHorizontal}>
                            <TextField text={Strings.Settings.ShowDesc} style={styles.settingName}></TextField>
                            <Switch ios_backgroundColor={"#999"} style={{marginTop:10}}
                                onValueChange={() => {updateLayout("showDesc")}}
                                value={layout.showDesc} />
                        </View>

                        <View style={styles.settingHolderHorizontal}>
                            <TextField text={Strings.Settings.ShowWebview} style={styles.settingName}></TextField>
                            <Switch ios_backgroundColor={"#999"} style={{marginVertical:10}}
                                onValueChange={() => {updateLayout("showWebview")}}
                                value={layout.showWebview} />
                        </View>
                    </View>

                    <View style={styles.catHolder}>
                        <TextField text={Strings.Settings.MinVersion} weight={600} style={styles.catTitle}></TextField>

                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.AndroidVersion} style={styles.settingName}></TextField>
                            <InputField ltr value={minVersion.android} onChangeText={text=>updateMinVersion("android", text)} placeholder={Strings.Settings.AndroidVersion}></InputField>
                        </View>
                        <View style={styles.settingHolder}>
                            <TextField text={Strings.Settings.IOSVersion} style={styles.settingName}></TextField>
                            <InputField ltr value={minVersion.ios} onChangeText={text=>updateMinVersion("ios", text)} placeholder={Strings.Settings.IOSVersion}></InputField>
                        </View>
                    </View>
                </ScrollView>
                <ButtonField title={Strings.Settings.Save} onPress={save}></ButtonField>
            </View>
        );
    }
    else return (
        <View style={styles.container}>
            <ActivityIndicator size="large" style={{marginVertical:200}}></ActivityIndicator>
        </View>
    );
    
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.General.MainBG,
    },
    catHolder: {
        padding:10,
        margin:10,
        backgroundColor:Colors.General.SubBg,
        borderColor: Colors.General.Text3,
        borderWidth:1,
    },
    catTitle: {
        fontSize:18,
        color:Colors.General.Text1,
        marginBottom:10,
        alignSelf:'center'
    },
    settingHolder: {

    },
    settingHolderHorizontal: {
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'
    },
    settingName: {
        fontSize:16,
        color:Colors.General.Text2,
    }
});
import { useEffect, useState } from 'react';
import stations from '../assets/data/stations.json'

function useFetch(url = '', options = null, converter = undefined) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        setLoading(true)
        if (url === 'local') {
            setData(converter(stations))
            setError(null)
            setLoading(false)
        }
        else if (url != '') {
            fetch(url, options)
            .then(res => res.json())
            .then(data => {
                setData(data)
                setError(null)
            })
            .catch(error => {
                setError(error)
                setData(null)
            })
            .finally(() => setLoading(false))
        }
    }, [url, options])

    return {data, error, loading}
}

export default useFetch;
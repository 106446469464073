import AsyncStorage from '@react-native-async-storage/async-storage';

export default class Storage {
  static GetProject = async () => {
    const projectId = await AsyncStorage.getItem('@project')
    if (projectId)
      return JSON.parse(projectId);
    return null;
  }
  static SetProject = async (project) => {
    if (project)
      await AsyncStorage.setItem('@project', JSON.stringify(project));
    else
      await AsyncStorage.removeItem('@project');
  }
  static GetUser = async () => {
    const user = await AsyncStorage.getItem('@user')
    if (user)
      return JSON.parse(user);
    return null;
  }
  static SetUser = async (user) => {
    if (user)
      await AsyncStorage.setItem('@user', JSON.stringify(user));
    else
      await AsyncStorage.removeItem('@user');
  }
  static Clear = async () => {
    await AsyncStorage.clear();
  }

}

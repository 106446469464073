import React from 'react';
import { StyleSheet,Dimensions} from 'react-native';
import Enums from '../constants/Enums';
import { MaterialCommunityIcons, EvilIcons,Ionicons,AntDesign, FontAwesome,MaterialIcons,Feather,SimpleLineIcons, Entypo} from '@expo/vector-icons';
import Layout from './Layout';
import Colors from './Colors';

export default class Icon extends React.Component {
  static SVGs = {
    Home: "M39.4 8.8C36 5.6 30.6 5.6 27.2 8.8L9.1 26C8.6 26.4 8.3 26.9 8.2 27.5 6.1 39.2 5.9 51.2 7.8 62.9L8.2 65.7 19.8 65.7 19.8 41.7C19.8 40.1 21.2 38.8 22.8 38.8L43.8 38.8C45.4 38.8 46.7 40.1 46.7 41.7L46.7 65.7 58.3 65.7 58.8 62.9C60.6 51.2 60.5 39.2 58.3 27.5 58.2 26.9 57.9 26.4 57.5 26L39.4 8.8ZM23.1 4.6C28.8-0.8 37.8-0.8 43.4 4.6L61.5 21.8C62.9 23 63.7 24.7 64.1 26.5 66.4 38.8 66.5 51.4 64.6 63.8L63.9 68.3C63.6 70.1 62 71.5 60.1 71.5L43.8 71.5C42.2 71.5 40.8 70.2 40.8 68.6L40.8 44.6 25.7 44.6 25.7 68.6C25.7 70.2 24.4 71.5 22.8 71.5L6.5 71.5C4.6 71.5 3 70.1 2.7 68.3L2 63.8C0 51.4 0.2 38.8 2.5 26.5 2.8 24.7 3.7 23 5 21.8L23.1 4.6Z",
    Chat: "M6.1 47.6C-2.9 25.2 13.4 0.7 37.1 0.7L38.4 0.7C56.2 0.7 70.6 15.4 70.6 33.5 70.6 53.6 54.6 70 34.8 70L3.5 70C2.3 70 1.1 69.2 0.7 67.9 0.3 66.7 0.7 65.4 1.7 64.5L9.5 58.1C9.9 57.8 10 57.4 9.8 56.9L6.1 47.6ZM37.1 6.8C17.6 6.8 4.3 26.9 11.7 45.3L15.4 54.6C16.6 57.5 15.7 60.9 13.3 62.9L12.1 63.9 34.8 63.9C51.3 63.9 64.7 50.3 64.7 33.5 64.7 18.7 52.9 6.8 38.4 6.8L37.1 6.8Z",
    Shows: "M63.4 32.4C58.1 33 52.6 33 47.2 32.4 43.4 32 40.3 29 39.8 25.1 39.2 19.7 39.2 14.2 39.8 8.7 40.3 4.8 43.4 1.8 47.2 1.4 52.6 0.8 58.1 0.8 63.4 1.4 67.2 1.8 70.3 4.8 70.8 8.7 71.4 14.2 71.4 19.7 70.8 25.1 70.3 29 67.2 32 63.4 32.4ZM65 9.4C64.9 8.2 64 7.3 62.8 7.1 57.9 6.6 52.8 6.6 47.9 7.1 46.7 7.3 45.7 8.2 45.6 9.4 45 14.4 45 19.4 45.6 24.4 45.7 25.6 46.7 26.5 47.9 26.7 52.8 27.2 57.9 27.2 62.8 26.7 64 26.5 64.9 25.6 65 24.4 65.6 19.4 65.6 14.4 65 9.4ZM24.8 71C19.5 71.6 14 71.6 8.6 71 4.8 70.6 1.7 67.6 1.2 63.7 0.6 58.3 0.6 52.7 1.2 47.3 1.7 43.4 4.8 40.4 8.6 40 14 39.4 19.5 39.4 24.8 40 28.6 40.4 31.7 43.4 32.2 47.3 32.8 52.7 32.8 58.3 32.2 63.7 31.7 67.6 28.6 70.6 24.8 71ZM26.4 48C26.3 46.8 25.4 45.9 24.2 45.7 19.3 45.2 14.2 45.2 9.3 45.7 8.1 45.9 7.1 46.8 7 48 6.4 53 6.4 58 7 63 7.1 64.2 8.1 65.1 9.3 65.3 14.2 65.8 19.3 65.8 24.2 65.3 25.4 65.1 26.3 64.2 26.4 63 27 58 27 53 26.4 48ZM24.8 32.4C19.5 33 14 33 8.6 32.4 4.8 32 1.7 29 1.2 25.1 0.6 19.7 0.6 14.2 1.2 8.7 1.7 4.8 4.8 1.8 8.6 1.4 14 0.8 19.5 0.8 24.8 1.4 28.6 1.8 31.7 4.8 32.2 8.7 32.8 14.2 32.8 19.7 32.2 25.1 31.7 29 28.6 32 24.8 32.4ZM26.4 9.4C26.3 8.2 25.4 7.3 24.2 7.1 19.3 6.6 14.2 6.6 9.3 7.1 8.1 7.3 7.1 8.2 7 9.4 6.4 14.4 6.4 19.4 7 24.4 7.1 25.6 8.1 26.5 9.3 26.7 14.2 27.2 19.3 27.2 24.2 26.7 25.4 26.5 26.3 25.6 26.4 24.4 27 19.4 27 14.4 26.4 9.4ZM47.2 40C52.6 39.4 58.1 39.4 63.4 40 67.2 40.4 70.3 43.4 70.8 47.3 71.4 52.7 71.4 58.3 70.8 63.7 70.3 67.6 67.2 70.6 63.4 71 58.1 71.6 52.6 71.6 47.2 71 43.4 70.6 40.3 67.6 39.8 63.7 39.2 58.3 39.2 52.7 39.8 47.3 40.3 43.4 43.4 40.4 47.2 40ZM45.6 63C45.7 64.2 46.7 65.1 47.9 65.3 52.8 65.8 57.9 65.8 62.8 65.3 64 65.1 64.9 64.2 65 63 65.6 58 65.6 53 65 48 64.9 46.8 64 45.9 62.8 45.7 57.9 45.2 52.8 45.2 47.9 45.7 46.7 45.9 45.7 46.8 45.6 48 45 53 45 58 45.6 63Z",
    Podcasts: "M47.1 70.3C47.3 68.8 47.5 67.3 47.6 65.8 59.5 61.1 68 49.5 68 36 68 18.4 53.6 4 36 4 18.4 4 4 18.4 4 36 4 49.5 12.4 61.1 24.3 65.8 24.5 67.4 24.6 68.9 24.8 70.2 10.4 65.5 0 52 0 36 0 16.1 16.1 0 36 0 55.9 0 72 16.1 72 36 72 52 61.5 65.6 47.1 70.3ZM25.1 50.3C24.6 51.6 24.2 52.9 24.1 54.5 18 50.6 14 43.8 14 36 14 23.9 23.9 14 36 14 48.2 14 58 23.9 58 36 58 43.8 54 50.6 47.9 54.5 47.8 52.9 47.4 51.6 46.9 50.3 51.2 47 54 41.8 54 36 54 26.1 45.9 18 36 18 26.1 18 18 26.1 18 36 18 41.8 20.8 47 25.1 50.3ZM36 28C40.4 28 44 31.6 44 36 44 40.4 40.4 44 36 44 31.6 44 28 40.4 28 36 28 31.6 31.6 28 36 28ZM36 40C38.2 40 40 38.2 40 36 40 33.8 38.2 32 36 32 33.8 32 32 33.8 32 36 32 38.2 33.8 40 36 40ZM36 48C40.4 48 44 50 44 56 44 75 40.5 80 36 80 31 80 28 75 28 56 28 50 31.6 48 36 48ZM33.9 73.7C34.7 76 35.4 76 36 76 36.3 76 37 76 38 73.7 39.3 70.3 40 64.3 40 56 40 53 39 52 36 52 33 52 32 53 32 56 32 64.5 32.6 70.4 33.9 73.7Z",
    Live: "M13 7.9c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4S15.2 7.9 13 7.9zM13 13.9c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2S14.1 13.9 13 13.9zM8.7 7.1C9.1 6.7 9.1 6 8.7 5.6c-0.4-0.4-0.9-0.4-1.3-0.1 -3.5 3.1-3.8 8.5-0.7 12 0.2 0.2 0.4 0.4 0.6 0.6 0.4 0.4 1 0.4 1.4-0.1 0.4-0.4 0.4-1-0.1-1.4 -2.6-2.4-2.8-6.5-0.4-9.2C8.4 7.4 8.5 7.2 8.7 7.1zM17.4 16.8c-0.4 0.4-0.4 1-0.1 1.4 0.4 0.4 1 0.5 1.4 0.1l0 0c3.5-3.1 3.8-8.5 0.7-12 -0.2-0.2-0.4-0.4-0.6-0.6 -0.4-0.4-1-0.4-1.4 0 -0.4 0.4-0.4 1 0 1.4 0 0 0.1 0.1 0.1 0.1 2.7 2.4 2.9 6.5 0.5 9.2 -0.2 0.2-0.3 0.4-0.5 0.5H17.4zM8.4 21.9c-5.5-2.5-7.9-9.1-5.4-14.6 1.1-2.4 3-4.3 5.4-5.4 0.5-0.2 0.7-0.8 0.5-1.3 -0.2-0.5-0.8-0.7-1.3-0.5C1.1 3.1-1.8 10.8 1.2 17.3c1.3 2.9 3.6 5.1 6.4 6.4 0.1 0.1 0.3 0.1 0.4 0.1 0.6 0 1-0.4 1.1-0.9C9.1 22.5 8.9 22.1 8.4 21.9zM18.4 0.1c-0.5-0.2-1.1 0-1.3 0.5 -0.2 0.5 0 1.1 0.5 1.3 5.5 2.5 8 9.1 5.4 14.6 -1.1 2.4-3 4.3-5.4 5.4 -0.5 0.2-0.7 0.8-0.5 1.3 0.2 0.4 0.5 0.6 0.9 0.6 0.2 0 0.3 0 0.4-0.1 6.5-3 9.4-10.7 6.4-17.2C23.5 3.7 21.3 1.4 18.4 0.1L18.4 0.1z"
  }
  static Type = {
    Send: {
      Icon: 'send',
      Enum: Enums.IconType.FontAwesome
    },
    Home: {
      Icon: 'home',
      Enum: Enums.IconType.Ionicons
    },
    Share: {
      Icon: 'share-outline',
      Enum: Enums.IconType.Ionicons
    },
    Up: {
      Icon: 'upcircleo',
      Enum: Enums.IconType.AntDesign
    },
    Down: {
      Icon: 'downcircleo',
      Enum: Enums.IconType.AntDesign
    },
    Timer: {
      Icon: 'ios-timer',
      Enum: Enums.IconType.Ionicons
    },
    Favorite: {
      Icon: 'heart-multiple-outline',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    FavoriteFull: {
      Icon: 'heart-multiple',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    Report: {
      Icon: 'alert-outline',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    Notification: {
      Icon: 'notifications-outline',
      Enum: Enums.IconType.Ionicons
    },
    Popup: {
      Icon: 'popup',
      Enum: Enums.IconType.Entypo
    },
    Settings: {
      Icon: 'ios-settings-outline',
      Enum: Enums.IconType.Ionicons
    },
    Playlist: {
      Icon: 'playlist-music',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    X: {
      Icon: 'close',
      Enum: Enums.IconType.AntDesign
    },
    Play: {
      Icon: 'play-circle',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    Pause: {
      Icon: 'pause-circle',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    PlayOutline: {
      Icon: 'ios-play',
      Enum: Enums.IconType.Ionicons
    },
    PauseOutline: {
      Icon: 'ios-pause',
      Enum: Enums.IconType.Ionicons
    },
    Star: {
      Icon: 'star',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    Terms: {
      Icon: 'ios-paper',
      Enum: Enums.IconType.Ionicons
    },
    Contact: {
      Icon: 'phone-in-talk',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    V: {
      Icon: 'check',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    VolumeOff: {
      Icon: 'volume-off',
      Enum: Enums.IconType.SimpleLineIcons
    },
    VolumeLow: {
      Icon: 'volume-1',
      Enum: Enums.IconType.SimpleLineIcons
    },
    VolumeHigh: {
      Icon: 'volume-2',
      Enum: Enums.IconType.SimpleLineIcons
    },
    Delete: {
      Icon: 'delete',
      Enum: Enums.IconType.MaterialIcons
    },
    Reminder: {
      Icon: 'alarm',
      Enum: Enums.IconType.MaterialIcons
    },
    Minus: {
      Icon: 'minus',
      Enum: Enums.IconType.AntDesign
    },
    List: {
      Icon: 'list-circle',
      Enum: Enums.IconType.Ionicons
    },
    Search: {
      Icon: 'ios-search',
      Enum: Enums.IconType.Ionicons
    },
    News: {
      Icon: 'ios-newspaper',
      Enum: Enums.IconType.Ionicons
    },
    Menu: {
      Icon: 'menu-outline',
      Enum: Enums.IconType.Ionicons
    },
    Shield: {
      Icon: 'shield-checkmark',
      Enum: Enums.IconType.Ionicons
    },
    ArrowBack: {
      Icon: 'keyboard-arrow-left',
      Enum: Enums.IconType.MaterialIcons
    },
    ArrowForward: {
      Icon: 'keyboard-arrow-right',
      Enum: Enums.IconType.MaterialIcons
    },
    Whatsapp: {
      Icon: 'logo-whatsapp',
      Enum: Enums.IconType.Ionicons
    },
    Logout: {
      Icon: 'logout',
      Enum: Enums.IconType.MaterialIcons
    },
    Chat: {
      Icon: 'chatbubble-outline',
      Enum: Enums.IconType.Ionicons
    },
    Podcast: {
      Icon: 'podcast',
      Enum: Enums.IconType.MaterialCommunityIcons
    },
    Audio: {
      Icon: 'sound',
      Enum: Enums.IconType.Entypo
    },
    Video: {
      Icon: 'video',
      Enum: Enums.IconType.Entypo
    },
    Forward30: {
      Icon: 'forward-30',
      Enum: Enums.IconType.MaterialIcons
    },
    Backward30: {
      Icon: 'replay-30',
      Enum: Enums.IconType.MaterialIcons
    },
    Businesses: {
      Icon: 'building-o',
      Enum: Enums.IconType.FontAwesome
    }
  }
  
  constructor() {
    super();
  }

  render() {
    switch (this.props.type.Enum) {
      case Enums.IconType.MaterialCommunityIcons:
        return <MaterialCommunityIcons name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.Ionicons:
        return <Ionicons name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.AntDesign:
        return <AntDesign name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.MaterialIcons:
        return <MaterialIcons name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.Feather:
        return <Feather name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.SimpleLineIcons:
        return <SimpleLineIcons name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.Entypo:
        return <Entypo name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.FontAwesome:
          return <FontAwesome name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      case Enums.IconType.FontAwesome:
          return <EvilIcons name={this.props.type.Icon} style={[styles.icon,this.props.style]} />;
      default:
        return null;
    }
  }
}
const vw = Dimensions.get('window').width;
const styles = StyleSheet.create({
  icon: {
    fontSize: Layout.Window.Width / 15,
    color:Colors.General.Text2,
  }
});

import React, { useMemo, useState } from 'react';
import { StyleSheet, View, Modal, SafeAreaView,TouchableOpacity, ActivityIndicator, ScrollView  } from 'react-native';
import Strings from '../constants/Strings';
import TextField from '../tools/TextField';
import Colors from '../constants/Colors';
import Icon from '../constants/Icon';
import EpisodeItem from '../components/EpisodeItem';
import useFetch from '../hooks/useFetch';
import Layout from '../constants/Layout';

const converter = (items) => {
    const episodes = []
    items.forEach(i => {
        episodes.push({
            id: i.Id,
            title: i.nameHE,
            description: i.freq,
            logo: `https://www.radio-head.co.il/images/stations/${i.url}.jpg`
        })
    })
    return episodes
}


export default function Episodes(props) {
    const categories = props.project.id === 'radiohaifa'? useFetch(props.project.categoriesUrl): undefined
    const [selectedCategory, setSelectedCategory] = useState(10)
    const fetchUrl = useMemo(() => {
        if (props.project.id === 'radiohaifa') 
            return props.project.episodesUrl + selectedCategory
        return props.project.episodesUrl
    }, [props.project.id, selectedCategory])

    const { data: episodes, loading } = useFetch(fetchUrl, null, props.project.id === 'radiohead'? converter: undefined);

    const close = () => {
        props.close(null);
    }
    return (
        <Modal
        animationType="slide"
        transparent={true}
        presentationStyle="pageSheet"
        visible={true}
        onRequestClose={close}>
            <SafeAreaView style={styles.container}>
            <View style={styles.modalFrame}>
                <View style={styles.modalHolder}>
                    <View style={styles.modalHeader}>
                        <TextField text={Strings.Push.SelectEpisode} style={styles.title}></TextField>
                        <TouchableOpacity onPress={close}>
                            <Icon type={Icon.Type.X} style={styles.x} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.modalContent}>
                        {
                            categories?.data?.length > 0 && (
                                <select style={{padding: 10, marginBottom: 20, fontSize: 16}} onChange={e => setSelectedCategory(e.target.value)}>
                                    {
                                        categories.data.map((c, i) => (
                                            <option key={c.id + ' ' + i} value={c.id} selected={c.id === selectedCategory}>{c.name}</option>
                                        ))
                                    }
                                </select>
                            )
                        }
                        {
                            loading? (
                                <ActivityIndicator size='large' color={Colors.General.Text1} />
                            ): episodes && episodes.length>0? (
                                <ScrollView>
                                    {
                                        episodes.map((item, i) => (
                                            <EpisodeItem onSelect={props.close} key={item.id + i} episode={item} ></EpisodeItem>
                                        ))
                                    }
                                </ScrollView>
                            ): null
                        }
                    </View>
                </View>
            </View>
            </SafeAreaView>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        width:'100%',
        maxWidth: Layout.Settings.DefaultMaxWidth,
        flex: 1,
        backgroundColor: Colors.General.SubBg,
        alignSelf:'center'
      },
    modalFrame:{
        flex: 1,
        backgroundColor:Colors.General.MainBG,
      },
      modalHolder:{
        alignItems: 'center',
        backgroundColor:Colors.General.MainBG,
        justifyContent: 'flex-start',
        width:'100%',
        height:'100%'
      },
      modalHeader: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        paddingHorizontal: 10,
      },
      modalContent:{
        flex:1,  
        backgroundColor:Colors.General.SubBg,
        width:'100%',
        padding:10
      },
      x: {
        color:Colors.General.Text2, 
        fontSize:28,
        marginStart:10,
        paddingVertical: 15,
      },
      title: {
        color:Colors.General.Text2,
        fontSize:20,
    },
});

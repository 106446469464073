import Constants from 'expo-constants';
import Enums from '../constants/Enums';
import Strings from '../constants/Strings';
import HttpService from './HttpService';
import systemLogo from '../assets/images/logo.png';


const defaultAuthor = { id: 0, name: Strings.General.PanelSystem, email: "admin@1075.fm", avatar: "https://1075.fm/wp-content/uploads/2019/05/20190514071048722089.jpg", rank: Enums.UserRank.Admin };

export default class AuthorsAPI {
    static Authors = [];
    
    static Get = async () => {
        return await HttpService.GetFromClientServer(Constants.manifest.extra.api.requests.authors, 100).then(authors => {
            AuthorsAPI.Authors.push(defaultAuthor)
            authors.forEach(a => {
                if (a.rank && a.rank != "")
                    AuthorsAPI.Authors.push(a)
            });
            return AuthorsAPI.Authors;
        });
    }
    static GetById = (id) => {
        return AuthorsAPI.Authors.find(a => a.id == id) || AuthorsAPI.Authors[0];
    }
    static GetByEmail = (email) => {
        email = email.toLowerCase();
        return AuthorsAPI.Authors.find(a => a.email == email) || AuthorsAPI.Authors[0];
    }
}
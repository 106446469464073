import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import TextField from '../tools/TextField';
import FadeInView from '../tools/FadeInView';

export default function PushPreview({data}) {
    if (!data) return null
    return (
      <FadeInView delay={0.2}>
        <View style={styles.container}>
          {
            data.logo? (
              <View style={styles.logoHolder}>
                <Image source={{uri:data.logo}} style={styles.logo} />
              </View>
            ): null
          }
          <View style={styles.content}>
            <TextField text={data.title} style={styles.title} weight={600} lines={2}></TextField>
            <TextField text={data.description} style={styles.description} weight={200} lines={2}></TextField>
          </View>
        </View>
      </FadeInView>
    )
}

const styles = StyleSheet.create({
  container: {
    flexDirection:'row',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    margin:Layout.Settings.MarginSmall,
  },
  content: {
    width: 0,
    flexGrow: 1,
  },
  logoHolder: {
    position:'relative',
    overflow:'hidden',
    marginEnd:Layout.Settings.MarginNormal,
    borderRadius:Layout.Settings.BorderRadius,
    width:40,
    height:40,
  },
  logo: {
    width:'100%',
    height:'100%',
    borderRadius:Layout.Settings.BorderRadius,
  },
  title: {
    fontSize: 16,
    width:'100%',
    color:Colors.General.Text1
  },
  description: {
    fontSize: 14,
    width:'100%',
    color:Colors.General.Text2,
    
  },
  time: {
    fontSize:13,
    color:Colors.General.Text2,
    alignSelf:'flex-end'
  },
  mediaAndDate: {
    flexDirection:'row',
    justifyContent:'flex-end',
    alignItems:'center',
  }
});

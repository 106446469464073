import React, {useCallback, useEffect, useState} from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';
import SendPush from '../components/SendPush';
import Pushes from '../components/Pushes';
import PushService from '../services/PushService'
import {useSelector} from 'react-redux'

export default function PushScreen(props) {
  const project = useSelector(state => state.project)
  const [pushes, setPushes] = useState([])
  const [loading, setLoading] = useState(true)

  const reload = useCallback(async () => {
    setLoading(true)
    setPushes(await PushService.Get(project))
    setLoading(false)
  }, [project])

  useEffect(() => {
    if (project) reload()
  }, [project])

  if (project) {
    return (
      <ScrollView style={styles.container}>
          <SendPush project={project} reload={reload}></SendPush>
          <Pushes pushes={pushes} loading={loading} reload={reload}></Pushes>
      </ScrollView>
    )
  } else return null
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.General.MainBG
  }
});

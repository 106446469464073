import React, { useState, useEffect } from 'react';
import {View, FlatList, StyleSheet, Modal, SafeAreaView, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Icon from '../constants/Icon';
import Strings from '../constants/Strings';
import AuthorsAPI from '../services/AuthorsAPI';
import ButtonField from '../tools/ButtonField';
import TextField from '../tools/TextField';
import UserItem from './UserItem';


export default function UserSelection(props) {
    const [users, setUsers] = useState(AuthorsAPI.Authors)
    
    const renderItem = ({item, index}) => {
        return (
            <UserItem user={item} onSelect={props.onSelect} last={index == users.length - 1}></UserItem>
        )
    }

    return (
        <Modal
        animationType="slide"
        transparent={true}
        presentationStyle="pageSheet"
        visible={true}
        onRequestClose={props.close}>
            <SafeAreaView style={styles.container}>
            <View style={styles.modalFrame}>
                <View style={styles.modalHolder}>
                    <View style={styles.modalHeader}>
                        <TextField text={Strings.Chat.Author} style={styles.title}></TextField>
                        <TouchableOpacity onPress={props.close}>
                            <Icon type={Icon.Type.X} style={styles.x} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.modalContent}>
                        <FlatList data={users}
                            renderItem={renderItem}
                            keyExtractor={u => u.id.toString()}
                            initialNumToRender={100} />
                        <ButtonField title={Strings.General.Confirm} onPress={props.close}></ButtonField>
                    </View>
                </View>
            </View>
            </SafeAreaView>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
      width:400,
      flex: 1,
      backgroundColor: Colors.General.SubBg,
      alignSelf:'center'
      },
    modalFrame:{
        flex: 1,
        backgroundColor:Colors.General.MainBG,
      },
      modalHolder:{
        alignItems: 'center',
        backgroundColor:Colors.General.MainBG,
        justifyContent: 'flex-start',
        width:'100%',
        height:'100%'
      },
      modalHeader: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        
        paddingHorizontal: 10,
      },
      modalContent:{
        flex:1,  
        backgroundColor:Colors.General.SubBg,
        width:'100%',
        padding:10
      },
      x: {
        color:Colors.General.Text2, 
        fontSize:28,
        marginStart:10,
        paddingVertical: 15,
      },
      title: {
        color:Colors.General.Text2,
        fontSize:20,
    },
})
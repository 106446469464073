import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ActivityIndicator, ImageBackground, Image, TouchableOpacity, ScrollView } from 'react-native';
import Strings from '../constants/Strings';
import PushService from '../services/PushService';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import TextField from '../tools/TextField';
import Enums from '../constants/Enums';
import Colors from '../constants/Colors';
import Icon from '../constants/Icon';
import Episodes from '../components/Episodes';
import AlertingService from '../services/AlertingService';
import Playlists from '../components/Playlists';
import {useSelector} from 'react-redux'
import preview from '../assets/images/preview.jpeg'
import Fire from '../services/Fire';


export default function PopupScreen(props) {
  const project = useSelector(state => state.project)
  const [title, setTitle] = useState('');
  const [freq, setFreq] = useState(0);
  const [logo, setLogo] = useState(project.signal.logo);
  const [page, setPage] = useState(project.pushPages[0].en);
  const [hideClub, setHideClub] = useState(false);
  const [popupStatus, setPopupStatus] = useState(Enums.PushStatus.Loading);
  const [popupMode, setPopupMode] = useState(Enums.PushMode.Page);
  const [showEpisodes, setShowEpisodes] = useState(false);
  const [showPlaylists, setShowPlaylists] = useState(false);
  const [episodeSelected, setEpisodeSelected] = useState(null);
  const [playlistSelected, setPlaylistSelected] = useState(null);

  useEffect(() => {
    if (!project.id) return
    Fire.shared.changeProject(project.id)
    Fire.shared.getSettings().then(s => {
      setTitle(s.popup.title)
      setLogo(s.popup.image)
      setFreq(s.popup.freq)
      setHideClub(s.popup.hideClub)
      if (s.popup.redirect.page) {
        setPopupMode(Enums.PopupMode.Page)
        setPage(s.popup.redirect.page)
      }
      else if (s.popup.redirect.episode) {
        setPopupMode(Enums.PopupMode.Episode)
        setEpisodeSelected({id: s.popup.redirect.episode})
      }
      else if (s.popup.redirect.playlist) {
        setPopupMode(Enums.PopupMode.Playlist)
        setPlaylistSelected({id: s.popup.redirect.playlist})
      }
      setPopupStatus(Enums.PushStatus.No)
    })
  }, [project])
    
  const alert = () => {
      if (__DEV__) { save(); return }
      if (title === '' && popupMode != Enums.PopupMode.Page)
          AlertingService.Alert(Strings.Popup.AlertTitle, Strings.Popup.AlertError)    
      else
          AlertingService.Prompt(Strings.Popup.AlertTitle, Strings.Popup.AlertDesc, save)
  }

  const save = async () => {
      setPopupStatus(Enums.PopupStatus.Loading);
      let redirect = {}
      if (popupMode == Enums.PopupMode.Page) 
        redirect = {page: page};
      else if (popupMode == Enums.PopupMode.Episode)
        redirect = {episode: episodeSelected.id}
      else if (popupMode == Enums.PopupMode.Playlist)
        redirect = {playlist: playlistSelected.id}
      
      const data = {
          title: title,
          freq: parseInt(freq),
          image: logo,
          redirect: redirect,
          hideClub: hideClub
      }
      await Fire.shared.updateSettings({popup: data})
      // await Fire.shared.savePopup(project, data)

      setPopupStatus(Enums.PopupStatus.No)
  }

  const toPage = (page) => {
      setPopupMode(Enums.PushMode.Page);
      setPage(page);
      setLogo(project.signal.logo)
  }
  const toEpisode = () => {
    setPopupMode(Enums.PushMode.Episode);
      setShowEpisodes(true);
  }
  const toPlaylist = () => {
    setPopupMode(Enums.PushMode.Playlist);
    setShowPlaylists(true);
  }
  const closeEpisode = (episode) => {
      setShowEpisodes(false);
      if (episode) {
          setEpisodeSelected(episode);
          setTitle(episode.title)
          setLogo(episode.logo)
      }
      else if (!episodeSelected) {
        setPopupMode(Enums.PopupMode.Page);
      }
  }
  const closePlaylist = (playlist) => {
      setShowPlaylists(false);
      if (playlist) {
          setPlaylistSelected(playlist);
          setTitle(playlist.title)
          setLogo(playlist.logo)
      }
      else if (!playlistSelected) {
          setPopupMode(Enums.PopupMode.Page);
      }
  }

    if (project) {
      return (
        <ScrollView style={styles.container}>
            <TextField text={Strings.Popup.Title} weight={600} style={styles.title}></TextField>
            {
              popupStatus == Enums.PopupStatus.Loading? (
                <ActivityIndicator size='large' color={'gray'} />
              ): (
                <View style={{width:'95%', alignSelf:'center'}}>
                  <InputField rtl value={title} onChangeText={text=>setTitle(text)} multiline placeholder={Strings.Popup.PlaceHolderTitle} returnKeyType="next" blurOnSubmit={false} />
                  <InputField value={logo} onChangeText={text=>setLogo(text)} placeholder={Strings.Popup.PlaceHolderLogo}></InputField>
                  <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                    <View style={{width:'49%'}}>
                      <TextField weight={600} text={Strings.Popup.Freq} lines={10}></TextField>
                      <InputField rtl value={freq} keyboardType='numeric' onChangeText={text=>setFreq(text)} placeholder={Strings.Popup.PlaceHolderTitle} returnKeyType="next" blurOnSubmit={false} />
                      
                      <TextField weight={600} text={Strings.Popup.ToUsers}></TextField>
                      <View style={styles.items}>
                        <TouchableOpacity onPress={() => setHideClub(false)} style={[styles.item, !hideClub? styles.itemActive: null]}>
                          <TextField weight={!hideClub? 700: 400} text='כולל המועדון' />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHideClub(true)} style={[styles.item, hideClub? styles.itemActive: null]}>
                          <TextField weight={hideClub? 700: 400} text='לא כולל המועדון' />
                        </TouchableOpacity>
                      </View>
                      
                      <TextField weight={600} text={Strings.Popup.Direct}></TextField>
                        <View style={styles.items}>
                            {
                                project.pushPages.map((p, index) => (
                                    <TouchableOpacity onPress={() => {toPage(p.en)}} key={index} style={[styles.item, page==p.en && popupMode==Enums.PopupMode.Page? styles.itemActive: null]}>
                                        <Icon type={p.icon} style={styles.icon} />
                                        <TextField weight={page==p.en && popupMode==Enums.PopupMode.Page? 700: 400} text={p.he} />
                                    </TouchableOpacity>
                                ))
                            }
                            <TouchableOpacity onPress={toEpisode} style={[styles.item, popupMode==Enums.PopupMode.Episode? styles.itemActive: null]}>
                                <Icon type={Icon.Type.News} style={styles.icon} />
                                <TextField weight={popupMode==Enums.PopupMode.Episode? 700: 400} text={Strings.Popup.Episodes} />
                            </TouchableOpacity>
                            {
                                project.playlistsUrl? (
                                    <TouchableOpacity onPress={toPlaylist} style={[styles.item, popupMode==Enums.PopupMode.Playlist? styles.itemActive: null]}>
                                        <Icon type={Icon.Type.Play} style={styles.icon} />
                                        <TextField weight={popupMode==Enums.PopupMode.Playlist? 700: 400} text={Strings.Popup.Playlists} />
                                    </TouchableOpacity>
                                ): null
                            }
                        </View>
                        <ButtonField title={Strings.Popup.Send} onPress={alert}></ButtonField>
                    </View>
                    <View style={{width:'49%'}}>
                      <TextField weight={600} text={Strings.Popup.Preview}></TextField>
                      <ImageBackground source={preview} style={{width:'100%', aspectRatio: 1/2.16, borderRadius: 10}}>
                        <View style={{width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.7)', alignItems:'center', justifyContent:'center'}}>
                          {logo && <Image source={{uri: logo}} style={{width:'95%', aspectRatio: 9/16, borderRadius:10}} />}
                        </View>
                      </ImageBackground>
                    </View>
                  </View>
                </View>
              )
            }
            {
              showEpisodes? <Episodes close={closeEpisode} project={project} />: null
            }
            {
              showPlaylists? <Playlists close={closePlaylist} project={project} />: null
            }
        </ScrollView>
      )
    } else return null
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:Colors.General.SubBg,
    paddingVertical: 10,
    width:'100%',
  },
  title: {
    color:Colors.General.Text1,
    fontSize:16,
    marginHorizontal:10,
    alignSelf: 'flex-start',
  },
  iconHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    width:22, height:22, marginBottom: 5
  },
  icon: {
    fontSize: 16,
    marginEnd: 5
  },
  items: {
      marginTop: 5,
      marginBottom: 10,
      flexDirection:'row',
      flexWrap:'wrap'
  },
  item: {
      minWidth: '19.3%',
      marginEnd:5,
      marginBottom: 5,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius:5,
      borderWidth: 1,
      borderColor:'rgba(255, 255, 255, 0.2)',
      justifyContent:'center',
      alignItems:'center',
      padding: 5,
      flexDirection:'row'
  },
  itemActive: {
      backgroundColor: Colors.General.Button
  },
  date: {
    backgroundColor:'#fff',
    borderRadius:5,
    paddingVertical:10,
    paddingHorizontal:10,
    width:'100%',
    fontSize:16,
    marginVertical:10
  }
});

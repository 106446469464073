import React from 'react';
import { StyleSheet, View, FlatList, ActivityIndicator } from 'react-native';
import Strings from '../constants/Strings';
import TextField from '../tools/TextField';
import Colors from '../constants/Colors';
import Push from '../components/Push';
import ButtonField from '../tools/ButtonField';
import Enums from '../constants/Enums';

export default function Pushes({pushes, loading, reload}) {
    
    const renderItem = ({index, item}) => {
        return <Push key={index} data={item}></Push>
    }

    return (
        <View style={styles.container}>
            <View style={{backgroundColor: Colors.General.MainBG, padding: 10,flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <TextField text={Strings.Push.Pushes} weight={600} style={styles.title}></TextField>
                <ButtonField title="עדכון" onPress={reload} type={Enums.ButtonTypes.Small} style={{paddingHorizontal:5}}></ButtonField>
            </View>
            {
                loading? (
                    <ActivityIndicator size="large" color={Colors.General.Text1} style={{margin:20, alignSelf:'flex-start'}}></ActivityIndicator>
                ): pushes?.length > 0? (
                    <FlatList
                        data={pushes}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => "key" + index}>
                    </FlatList>
                ): (
                    <TextField text={Strings.Push.NoPushes} style={styles.no}></TextField>
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  input: {
      borderColor: '#dfdfdf',
      borderWidth:1,
      borderRadius:5,
      paddingVertical:5,
      paddingHorizontal:10,
      width:'100%',
      fontSize:16,
      marginVertical:5
  },
  title: {
    color:Colors.General.Text1,
    fontSize:16
  },
  no: {
    color:Colors.General.Text2,
    fontSize:16,
    margin:10
  }
});

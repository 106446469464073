import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, ScrollView, Alert, Clipboard, TouchableOpacity, ActivityIndicator} from 'react-native';
import Fire from '../services/Fire';
import Colors from '../constants/Colors';
import Strings from '../constants/Strings';
import Layout from '../constants/Layout';
import Icon from '../constants/Icon';
import TextField from '../tools/TextField';
import Storage from '../services/Storage';
import AuthorsAPI from '../services/AuthorsAPI';
import Enums from '../constants/Enums';
import ChatMessage from '../components/ChatMessage';
import InputField from '../tools/InputField';
import Skeleton from '../components/Skeleton';
import UserSelection from '../components/UserSelection';
import ButtonField from '../tools/ButtonField';
import UserItem from '../components/UserItem';
import PushService from '../services/PushService';
import { useSelector } from 'react-redux';
import AlertingService from '../services/AlertingService';

let localMessages = []

export default function ChatScreen(props) {
  const [showUserScreen, setShowUserScreen] = useState(false)
  const scrollViewRef = useRef();
  const [sendWithPush, setSendWithPush] = useState(false);
  const [isSending, setIsSending] = useState(false)
  const project = useSelector(state => state.project)
  const [refreshing, setRefreshing] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null)
  const [messages, setMessages] = useState([]);

  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessages([]);

    Storage.GetUser().then(u => {
      setUser(u)
      setSelectedUser(u)
    });
    setTimeout(() => {
      Fire.shared.onNewChat(m => {
        if (localMessages.findIndex(ma => ma._id == m._id) > -1)
          return
        m.user = AuthorsAPI.GetById(m.user.id);
        if (messages.length > 0)
          m.prev = messages[messages.length-1];
        else 
          m.prev = null;
        
        localMessages = localMessages.concat(m)
        setMessages([...localMessages]);
        setRefreshing(false);
      });
    }, 1000)
    
    return () => {
      Fire.shared.offChat();
    }
  }, []);
  useEffect(() => {
    if (messages && messages.length>0) {
      setTimeout(() => {
        scrollViewRef.current.scrollToEnd({animated: true})
      }, 500)
    }
  }, [messages]);

  const send = async () => {
    if (message == "")
      return

    setIsSending(true)

    let msg = message
    if (msg.length > 50)
      msg = msg.match(/.{1,50}(\s|$)/g)[0] + "..."

    Fire.shared.send([
      {text: message, user: {id: selectedUser.id }}
    ])
  
    if (sendWithPush)
      await PushService.Send({project: project.id, title: Strings.Chat.PushTitle + " @" + selectedUser.name, body: msg, data: {page: "Chat"}});
    
    setMessage("")
    setIsSending(false)
  }
  const onSelectUser = user => {
    setSelectedUser(user)
    setShowUserScreen(false)
  }
  const onDelete = (msg) => {
    if (msg.user.id != user.id && user.rank != Enums.UserRank.Admin)
      return;
AlertingService.Prompt(Strings.Chat.RemoveTitle + " " + user.name, msg.text, () => {
  localMessages = localMessages.filter(message => message._id !== msg._id)
  setMessages([...localMessages]);
  Fire.shared.remove(msg._id);
})
  }
  
    return (
      <View style={styles.container}>
      <ScrollView style={styles.container} ref={scrollViewRef}>
        {
          refreshing? (
            <View style={styles.container}>
              <Skeleton type={Enums.Skeleton.ChatMessage}></Skeleton>
              <Skeleton type={Enums.Skeleton.ChatMessage}></Skeleton>
              <Skeleton type={Enums.Skeleton.ChatMessage}></Skeleton>
              <Skeleton type={Enums.Skeleton.ChatMessage}></Skeleton>
              <Skeleton type={Enums.Skeleton.ChatMessage}></Skeleton>
            </View>
          ): (
            messages.map((item, i) => (
                <ChatMessage key={i} prev={i>0? messages[i-1]: null} message={item} onDelete={item.user.id == user.id || user.rank == Enums.UserRank.Admin? onDelete: null}></ChatMessage>
              ))
          )
        }
      </ScrollView>
      <View style={styles.sendBox}>
        {
          user && user.rank == Enums.UserRank.Admin? (
            <TouchableOpacity onPress={() => {setShowUserScreen(true)}} style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              {
                selectedUser? (
                  <UserItem user={selectedUser} onSelect={() => {}} last={true}></UserItem>
                ): null
              }
              <View style={{flexDirection:'row', alignItems:'center', flex:1, justifyContent:'flex-end'}}>
                <Icon type={Icon.Type.Search} style={{marginEnd:5,fontSize:15,marginTop:3,color:Colors.General.Text1}}></Icon>
                <TextField text={Strings.Chat.SelectAuthor} style={{fontSize:16}} underline></TextField>
              </View>
            </TouchableOpacity>
          ): null
        }
        {
          showUserScreen? (
            <UserSelection close={() => setShowUserScreen(false)} onSelect={onSelectUser}></UserSelection>
          ): null
        }
        {
          user && user.rank == Enums.UserRank.Admin? (
            <TouchableOpacity onPress={() => {setSendWithPush(prev => !prev)}}>
              <View style={styles.checkbox}>
                <View style={[styles.checkboxV, sendWithPush? styles.checkboxVActive: null]}>
                  <Icon type={Icon.Type.V} style={styles.checkboxIcon}></Icon>
                </View>
                <TextField text={Strings.Chat.Push} style={styles.checkboxText}></TextField>
              </View>
            </TouchableOpacity>
          ): null
        }
        <View style={styles.sendHolder}>
          {
            isSending? (
              <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start', margin:10}}>
                <ActivityIndicator size="small" color={Colors.General.Text1}></ActivityIndicator>
                <TextField text={Strings.Chat.Sending} style={{color:Colors.General.Text1, fontSize:18,marginStart: 10}}></TextField>
              </View>
            ): (
              <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:'100%'}}>
                <InputField style={styles.input} value={message} onChangeText={text => {setMessage(text)}}></InputField>
                <ButtonField title={Strings.Chat.Send} onPress={send} style={{paddingHorizontal:5}} icon={Icon.Type.Send}></ButtonField>
              </View>
            )
          }
        </View>
      </View>
    </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor: Colors.General.SubBg,
  },
  refreshHolder: {
    flex:1,
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:'red'
  },
  sendBox: {
    padding:Layout.Settings.MarginSmall, backgroundColor:Colors.General.MainBG,
  },
  sendHolder: {
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems:'center',
    width:'100%'
  },
  inputHolder: {
    alignItems:'center',
    position:'relative',
    width:'100%'
  },
  input: {
    fontSize:18,
    flex:1,
    marginEnd:10
  },
  checkbox: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    margin:5,
    paddingVertical:5,
    borderTopColor: Colors.General.SubBg,
    borderBottomColor: Colors.General.SubBg,
    borderTopWidth:1,
    borderBottomWidth:1
  },
  checkboxV: {
    width:25,
    height:25,
    borderRadius:5,
    backgroundColor:Colors.General.Text1,
    borderWidth: 1,
    borderColor: '#ddd',
    marginEnd:5,
    alignItems:'center',
    justifyContent:'center'
  },
  checkboxVActive: {
    backgroundColor: Colors.General.Button
  },
  checkboxText: {
    fontSize:18
  },
  checkboxIcon: {
    fontSize:22
  }
});

import * as React from 'react';

export const navigationRef = React.createRef();

export function navigate(name) {
  navigationRef.current?.navigate(name);
}
export function navigateParams(name, params) {
  navigationRef.current?.navigate(name, params);
}
export function current() {
  return navigationRef.current?.getCurrentRoute()
}
import React, {useMemo} from 'react';
import { StyleSheet, View, Image  } from 'react-native';
import Strings from '../constants/Strings';
import TextField from '../tools/TextField';
import Colors from '../constants/Colors';
import DateFilter from '../tools/DateFilter';
import defaultLogo from '../assets/images/icon.jpg'
import Layout from '../constants/Layout';

export default function Push({data}) {
  const statsText = useMemo(() => {
    const segment = data.segment.name
    const sentTo = `${data.count.sentTo} ${Strings.Push.Devices}`
    const failed = `${data.count.failed} ${Strings.Push.Failed}`
    const converted = `${data.count.converted} ${Strings.Push.Converted} (${Math.floor(data.count.converted / data.count.sentTo * 100)}%)`
    return `${segment} | ${sentTo} | ${failed} | ${converted}`
  }, [data])
  return (
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.logoHolder}>
            <Image source={{uri:data.logo}} style={styles.logo}></Image>
          </View>
          <View style={styles.text}>
            <TextField lines={2} style={styles.title} text={data.title} weight={600}></TextField>
            <TextField lines={3} style={styles.body} text={data.body} weight={400}></TextField>
          </View>
        </View>
        <View style={styles.bottom}>
            <TextField style={styles.devices} text={statsText} weight={200}></TextField>
            <TextField style={styles.date} text={DateFilter.DateTime(data.date)} weight={200}></TextField>
        </View>
      </View>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.General.SubBg,
    alignItems: 'flex-start',
    marginBottom:10,
    padding:10
  },
  bottom: {
    flexDirection:'row',
    justifyContent:'space-between',
    width:'100%',
    marginTop: 10,
    alignItems:'center',
  }, 
  devices: {
    color:Colors.General.Text1,
    fontSize:14,
    marginEnd:10
},   
  date: {
      color:Colors.General.Text3,
      fontSize:14
  },
  title: {
    color:Colors.General.Text1,
    fontSize:16,
    marginBottom:5
  },
  body: {
      color:Colors.General.Text2,
      fontSize:14
  },
  content: {
    flexDirection:'row',
    alignItems:'top',
    justifyContent:'center',
  },
  text: {
    paddingHorizontal: Layout.Settings.MarginNormal,
    width: Layout.Settings.DefaultMaxWidth - 60,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  logoHolder: {
    position:'relative',
    overflow:'hidden',
    borderRadius:Layout.Settings.BorderRadius,
    width:50,
    height:50,
  },
  logo: {
    width:'100%',
    height:'100%',
    borderRadius:Layout.Settings.BorderRadius,
  },
});

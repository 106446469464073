import React, {useState} from 'react';
import { StyleSheet,Dimensions,Animated,Platform} from 'react-native';
export default function FadeInView(props) {
    const [fadeAnim] = useState(new Animated.Value(0)); // Initial value for opacity: 0

    React.useEffect(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 400,
        delay: 1000 * (props.delay || 0.2),
        useNativeDriver: Platform.OS == "web"? false: true,
      }).start();
    }, []);
  
    return (
      <Animated.View // Special animatable View
        style={{
          ...props.style,
          opacity: fadeAnim,
        }}>
        {props.children}
      </Animated.View>
    );
}
const vw = Dimensions.get('window').width;

const styles = StyleSheet.create({
  
});

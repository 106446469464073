import React, { useCallback, useState } from 'react';
import { StyleSheet, View, ActivityIndicator, TouchableOpacity, ScrollView } from 'react-native';
import Strings from '../constants/Strings';
import PushService, {SEGMENTS} from '../services/PushService';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import TextField from '../tools/TextField';
import Enums from '../constants/Enums';
import Colors from '../constants/Colors';
import Icon from '../constants/Icon';
import Episodes from './Episodes';
import AlertingService from '../services/AlertingService';
import PushPreview from './PushPreview';
import Playlists from './Playlists';
// import HttpService from '../services/HttpService';
const allAlarmZones = []
function addZones(zones) { zones.forEach((z) => allAlarmZones.push({name: z, selected: false})) }
addZones(['חיפה', 'קריות', 'עכו', 'נהריה', 'יישובי חוף הכרמל', 'עתלית'])
function makeString(arr) {
    if (arr.length === 1) return arr[0];
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return firsts.join(', ') + ' ו' + last;
}
const zero = a => a > 10? a: '0' + a
export default function SendPush({project, reload}) {
    const [alarmZones, setAlarmZones] = useState(allAlarmZones)
    const [title, setTitle] = useState(__DEV__? 'כותרת פוש': '');
    const [body, setBody] = useState(__DEV__? 'תוכן פוש': '');
    // const [logo, setLogo] = useState('http://www.995.co.il/wp-content/uploads/2022/12/20221215141624907665.png');
    const [logo, setLogo] = useState(project.signal.logo);
    const [page, setPage] = useState(project.pushPages[0].en);
    const [presets, setPresets] = useState(project.presets);
    const [pushStatus, setPushStatus] = useState(Enums.PushStatus.No);
    const [pushMode, setPushMode] = useState(Enums.PushMode.Page);
    const [showEpisodes, setShowEpisodes] = useState(false);
    const [showPlaylists, setShowPlaylists] = useState(false);
    const [episodeSelected, setEpisodeSelected] = useState(null);
    const [playlistSelected, setPlaylistSelected] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState(__DEV__? "Test Users": "Subscribed Users")

    const d = new Date()
    const [selectedDate, setSelectedDate] = useState(`${d.getFullYear()}-${zero(d.getMonth() + 1)}-${zero(d.getDate())}T${zero(d.getHours())}:${zero(d.getMinutes())}`);
    const onChangeDate = e => {
        setSelectedDate(e.target.value)
    }
    const alert = () => {
        if (__DEV__) { push(); return }
        if (body === '')
            AlertingService.Alert(Strings.Push.AlertTitle, Strings.Push.AlertError)    
        else
            AlertingService.Prompt(Strings.Push.AlertTitle, Strings.Push.AlertDesc, push)
    }

    const push = async () => {
        setPushStatus(Enums.PushStatus.Loading);
        let location = {}
        if (pushMode == Enums.PushMode.Page) 
            location = {page: page};
        else if (pushMode == Enums.PushMode.Episode) {
            location = {
                item: episodeSelected.id,
                episode: episodeSelected.id
            }
        }
        else if (pushMode == Enums.PushMode.Playlist)
            location = {playlist: playlistSelected.id}
        const imgUrl = logo || project.signal.logo
        const logos = {
            ios_attachments: {
                id1: imgUrl
            },
            big_picture: imgUrl,
            huawei_big_picture: imgUrl,
            large_icon: imgUrl,
            huawei_large_icon: imgUrl,
        }
        const datesDiff = new Date(selectedDate) - new Date()
        const data = {
            contents: {"en": body},
            headings: {"en": title},
            data: { ...location },
            segment: selectedSegment,
            ...datesDiff > 0? {send_after: new Date(selectedDate)}: {},
            ...(logo? logos: {})
        }
        await PushService.Send(project, data)

        setPushStatus(Enums.PushStatus.No);
        reload()
    }

    const toPage = (page) => {
        setPushMode(Enums.PushMode.Page);
        setPage(page);
        setLogo(project.signal.logo)
    }
    const toEpisode = () => {
        setPushMode(Enums.PushMode.Episode);
        setShowEpisodes(true);
    }
    const toPlaylist = () => {
        setPushMode(Enums.PushMode.Playlist);
        setShowPlaylists(true);
    }
    const closeEpisode = (episode) => {
        setShowEpisodes(false);
        if (episode) {
            setEpisodeSelected(episode);
            setTitle(episode.title)
            setBody(episode.description)
            setLogo(episode.logo)
        }
        else if (!episodeSelected) {
            setPushMode(Enums.PushMode.Page);
        }
    }
    const closePlaylist = (playlist) => {
        setShowPlaylists(false);
        if (playlist) {
            setPlaylistSelected(playlist);
            setTitle(playlist.title)
            setBody(playlist.description)
            setLogo(playlist.logo)
        }
        else if (!playlistSelected) {
            setPushMode(Enums.PushMode.Page);
        }
    }

    // const getItem = useCallback(async (id) => {
    //     const item = (await HttpService.Get(`https://1075.fm/api/v2/items?id=${id}`))[0]
    //     if (item)
    //         closeEpisode({...item, title: '', description: ''})
    // })
    const setAlarmZonePush = useCallback(index => {
        setPushMode(Enums.PushMode.Page);
        setPage('Home');
        setLogo('https://1075.fm/wp-content/uploads/2023/03/20220515124726546191.jpg')
        setTitle('')
        const temp = alarmZones
        temp[index].selected = !temp[index].selected
        const result = makeString(temp.filter(t => t.selected).map(t => t.name))
        setBody('אזעקה ב' + result)
        setAlarmZones(temp)
    }, [alarmZones])

    return (
        <View style={styles.container}>
            <TextField text={Strings.Push.Title} weight={600} style={styles.title}></TextField>
            {
                pushStatus == Enums.PushStatus.Loading? (
                    <ActivityIndicator size='large' color={'gray'} />
                ): (
                    <View style={{width:'95%'}}>
                        {
                            project.presets?.length > 0 && (
                                <ScrollView contentContainerStyle={{width:'100%'}} horizontal={true} showsHorizontalScrollIndicator={false}>
                                    {
                                        project.presets.map((p, index) => (
                                            <TouchableOpacity onPress={() => {toPage('Home'); setTitle(p.title); setBody(p.body); setLogo(p.logo)}} key={index} style={styles.item}>
                                                <TextField text={p.show} />
                                            </TouchableOpacity>
                                        ))
                                    }
                                </ScrollView>
                            )
                        }
                        {
                            project.id === 'radiohaifa' && (
                                <ScrollView contentContainerStyle={styles.items} horizontal={true} showsHorizontalScrollIndicator={false}>
                                {
                                    alarmZones.map((zone, index) => (
                                        <TouchableOpacity key={index} onPress={() => setAlarmZonePush(index)} style={[styles.item, zone.selected && styles.itemActive]}>
                                            <TextField text={zone.name} />
                                        </TouchableOpacity>
                                    ))
                                }   
                                </ScrollView>
                            )
                        }
                        <InputField 
                            rtl
                            value={title} 
                            onChangeText={text=>setTitle(text)} multiline
                            placeholder={Strings.Push.PlaceHolderTitle}
                            returnKeyType="next"
                            blurOnSubmit={false}
                            ></InputField>
                        <InputField rtl value={body} onChangeText={text=>setBody(text)} multiline placeholder={Strings.Push.PlaceHolderBody}></InputField>
                        <InputField value={logo} onChangeText={text=>setLogo(text)} placeholder={Strings.Push.PlaceHolderLogo}></InputField>

                        <TextField weight={600} text={Strings.Push.Direct}></TextField>
                        
                        <ScrollView contentContainerStyle={styles.items} horizontal={true} showsHorizontalScrollIndicator={false}>
                            {
                                project.pushPages.map((p, index) => (
                                    <TouchableOpacity onPress={() => {toPage(p.en)}} key={index} style={[styles.item, page==p.en && pushMode==Enums.PushMode.Page? styles.itemActive: null]}>
                                        <Icon type={p.icon} style={styles.icon} />
                                        <TextField weight={page==p.en && pushMode==Enums.PushMode.Page? 700: 400} text={p.he} />
                                    </TouchableOpacity>
                                ))
                            }
                            <TouchableOpacity onPress={toEpisode} style={[styles.item, pushMode==Enums.PushMode.Episode? styles.itemActive: null]}>
                                <Icon type={Icon.Type.News} style={styles.icon} />
                                <TextField weight={pushMode==Enums.PushMode.Episode? 700: 400} text={Strings.Push.Episodes} />
                            </TouchableOpacity>
                            {
                                project.playlistsUrl? (
                                    <TouchableOpacity onPress={toPlaylist} style={[styles.item, pushMode==Enums.PushMode.Playlist? styles.itemActive: null]}>
                                        <Icon type={Icon.Type.Play} style={styles.icon} />
                                        <TextField weight={pushMode==Enums.PushMode.Playlist? 700: 400} text={Strings.Push.Playlists} />
                                    </TouchableOpacity>
                                ): null
                            }
                        </ScrollView>
                        
                        <TextField weight={600} text={Strings.Push.ToUsers}></TextField>
                        <ScrollView contentContainerStyle={styles.items} horizontal={true} showsHorizontalScrollIndicator={false}>
                            {
                                SEGMENTS.map((segment, index) => (
                                    <TouchableOpacity onPress={() => {setSelectedSegment(SEGMENTS[index].key)}} key={index} style={[styles.item, selectedSegment === segment.key? styles.itemActive: null]}>
                                        <TextField weight={selectedSegment === segment.key? 700: 400} text={segment.name} />
                                    </TouchableOpacity>
                                ))
                            }
                        </ScrollView>
                        <TextField weight={600} text={Strings.Push.Preview}></TextField>
                        <View style={{marginBottom: 10, marginTop:5, borderRadius:5, borderWidth: 1, borderColor:'rgba(255,255,255,0.2)', backgroundColor:'rgba(255,255,255,0.1)'}}>
                            <PushPreview data={{title: title, description: body, logo: logo}} />
                        </View>
                        <TextField weight={600} text={Strings.Push.PlaceHolderDate}></TextField>
                        <input type='datetime-local' value={selectedDate} onChange={onChangeDate} style={{
                            backgroundColor:'#fff',
                            borderRadius:5,
                            padding:10,
                            fontSize:16,
                            margin:'5px 0 10px'}} />
                        <ButtonField title={Strings.Push.Send} onPress={alert}></ButtonField>
                    </View>
                )
            }
            {
                showEpisodes? <Episodes close={closeEpisode} project={project} />: null
            }
            {
                showPlaylists? <Playlists close={closePlaylist} project={project} />: null
            }
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:Colors.General.SubBg,
    paddingVertical: 10
  },
  title: {
    color:Colors.General.Text1,
    fontSize:16,
    marginHorizontal:10,
    alignSelf: 'flex-start',
  },
  iconHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    width:22, height:22, marginBottom: 5
  },
  icon: {
    fontSize: 16,
    marginEnd: 5
  },
  items: {
      width:'100%',
      marginTop: 5,
      marginBottom: 10
  },
  item: {
      marginEnd:5,
      marginBottom: 5,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius:5,
      borderWidth: 1,
      borderColor:'rgba(255, 255, 255, 0.2)',
      justifyContent:'center',
      alignItems:'center',
      padding: 5,
      paddingHorizontal: 15,
      flexDirection:'row'
  },
  itemActive: {
      backgroundColor: Colors.General.Button
  },
  date: {
    backgroundColor:'#fff',
    borderRadius:5,
    paddingVertical:10,
    paddingHorizontal:10,
    width:'100%',
    fontSize:16,
    marginVertical:10
  }
});

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, Dimensions } from 'react-native';
import logo from '../assets/images/logo.png'; 
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import Enums from '../constants/Enums';
import Strings from '../constants/Strings';
import Storage from '../services/Storage';
import TextField from '../tools/TextField';
import Icon from '../constants/Icon';
import {useDispatch, useSelector} from 'react-redux'
import { Logout, SetProject } from '../redux/app-redux';
import Fire from '../services/Fire';
import ButtonField from '../tools/ButtonField';
import Menu from './Menu';

export default function Header() {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const project = useSelector(state => state.project)
  const [showMenu, setShowMenu] = useState(false)
  const [showSelectProject, setShowSelectProject] = useState(false)
  const [projects, setProjects] = useState([])
  
  const logout = async () => {
    await Storage.SetUser(null);
    dispatch(Logout())
  }

  const changeProject = async (pId) => {
    setShowSelectProject(false)
    const selected = Fire.shared.projects.find(p => p.id == pId)
    await Storage.SetProject(selected.id)
    dispatch(SetProject(selected))
  }

  const toggleChangeProject = () => {
    setShowSelectProject(prev => !prev)
  }

  useEffect(() => {
    if (!user) return
    if (user.email === 'lisharlb@gmail.com')
      setProjects(Fire.shared.projects)
    else {
      setProjects(Fire.shared.projects.filter(p => p.id != 'radiohead'))
    }
  }, [user])

    return (
        <View style={styles.container}>
           {
             project? (
               <View style={{flexDirection:'row'}}>
                  <TouchableOpacity onPress={() => setShowMenu(true)}>
                    <Icon type={Icon.Type.Menu} style={styles.menu}></Icon>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => toggleChangeProject()} style={[styles.selectedProject, showSelectProject? {backgroundColor:'transparent'}: null]}>
                    <View style={styles.logoHolder}>
                      <Image style={styles.logo} source={project.logo}></Image>
                    </View>
                  </TouchableOpacity>
                  {
                    showSelectProject? (
                    <View style={styles.selectedProjectHolder}>
                    {
                    projects.map((p, i) => (
                      <TouchableOpacity key={i} onPress={() => changeProject(p.id)} style={[styles.segment, styles.segmentRight, styles.tab, project.id == p.id? styles.tabSelected: null]}>
                        <View style={styles.logoHolder}>
                          <Image style={styles.logo} source={p.logo}></Image>
                        </View>
                      </TouchableOpacity>
                    ))
                  }
                  </View>
                    ): null
                  }
                  
                 
               </View>
             ): null
            }
            {
              user? (
                <View>
                  <TextField text={user.name} style={{color: Colors.General.Text1, fontSize:14}}></TextField>
                  <ButtonField title={Strings.Login.Logout} onPress={logout} type={Enums.ButtonTypes.Small} icon={Icon.Type.Logout}></ButtonField>
                </View>
              ): null
            }
            {
              showMenu? (
                <Menu close={() => {setShowMenu(false)}}></Menu>
              ): null
            }
        </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection:'row',
    paddingTop:10,
    width:'100%',
    zIndex:10,
    position:'relative',
    paddingHorizontal:Layout.Settings.MarginNormal,
  },
  menu: {
    color:Colors.General.Text1,
    fontSize:50,
    marginEnd:10
  },
  logoHolder: {
    shadowColor:Colors.General.Text1,
    shadowOffset: {
      width:0,
      height:0
    },
    shadowOpacity:50,
    shadowRadius:10,
    elevation:5,
    borderRadius:100,
  },
  logo: {
      width: 40,
      height: 40,
      borderRadius:100,
  },
  selectedProject: {
    backgroundColor:Colors.General.SubBg,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding:10
  },
  selectedProjectHolder: {
    position:'absolute',
    zIndex:1,
    flexDirection:'row',
    alignItems:'center', 
    backgroundColor:Colors.General.MainBG,
    padding:10,top:'100%',
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius:10
  },
  tab: {
    backgroundColor:Colors.General.SubBg,
    borderRadius:10,
    padding:10,
    marginHorizontal:10
  }
});

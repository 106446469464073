import firebase from 'firebase';
import megamixLogo from '../assets/images/projects/megamix.png'
import radioheadLogo from '../assets/images/projects/radiohead.png'
import thepanelLogo from '../assets/images/projects/thepanel.png'
import radiohaifaLogo from '../assets/images/projects/radiohaifa.png'
import hameshLogo from '../assets/images/projects/hamesh.png'
import Icon from '../constants/Icon';
class Fire {
  projects = [
    {
      id: "radiohead",
      name: "רדיו הד",
      logo: radioheadLogo,
      episodesUrl: 'local',//"https://api.radio-head.co.il/api/stations",
      pages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home},
        {he: "פושים", en: "Push", icon: Icon.Type.Notification}
      ],
      pushPages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home}
      ],
      signal: {
        auth: 'MmMyNjU4ZGMtOWU0Ni00NmI0LTg3ZmQtOTdiMDFmODQwY2Fl',
        key: '4292f167-1e1f-4ea5-a711-18ea44f28361',
        logo: 'https://www.radio-head.co.il/images/logos/512.png'
      },
      fb_config: {
        apiKey: "AIzaSyAZV3UtnWqbGaw5qWhUm_D-Mdyxqj0kvPA",
        authDomain: "radio-head-2020.firebaseapp.com",
        databaseURL: 'https://radio-head-2020.firebaseio.com/',
        projectId: "radio-head-2020",
        storageBucket: "radio-head-2020.appspot.com",
        messagingSenderId: "414671964081",
        appId: "1:414671964081:android:19b0ed1b261e4e13649f90",
      }
  },
    {
        id: "thepanel",
        name: "הפאנל",
        logo: thepanelLogo,
        pages: [
          {he: "ראשי", en: "Home", icon: Icon.Type.Home},
          {he: "פושים", en: "Push", icon: Icon.Type.Notification},
          {he: "צ'ט כתבים", en: "Chat", icon: Icon.Type.Chat},
          {he: "עסקים", en: "Businesses", icon: Icon.Type.Businesses}
        ],
        pushPages: [
          {he: "ראשי", en: "Home", icon: Icon.Type.Home},
          {he: "צ'ט כתבים", en: "Chat", icon: Icon.Type.Chat}
        ],
        episodesUrl: "https://1075.fm/api/v2/items/?limit=20&category=9",
        signal: {
          auth: 'ZGVhNjdjNGMtNjdjOS00ZWFhLTllZWYtYjdkMmRjZTAyMDkw',
          key: '9e739113-eb99-41eb-a31e-e56118813cfa',
          logo: 'https://1075.fm/wp-content/uploads/2016/12/20210726154645619952.jpg'
        },
        fb_config: {
          apiKey: "AIzaSyAJGsuxR8mRj9QClmt9PE8IKyHak6u_BqQ",
          authDomain: "the-panel-6f1e7.firebaseapp.com",
          databaseURL: 'https://the-panel-6f1e7-default-rtdb.firebaseio.com/',
          projectId: "the-panel-6f1e7",
          storageBucket: "the-panel-6f1e7.appspot.com",
          messagingSenderId: "611564328473",
          appId: "1:611564328473:web:6f9be796143684e40cfc88",
          measurementId: "G-C5JDZCBDLG"
        },
        presets: [
          {
            show: 'קטן ב-2',
            title: '',
            body: '📹 עכשיו בלייב: קטן ב-2 - הצטרפו אלינו!',
            logo: 'https://1075.fm/wp-content/uploads/2017/08/20210917181242484384.jpg'
          },
          {
            show: 'הפלייאוף',
            title: 'הפלייאוף',
            body: 'יצאנו לדרך',
            logo: 'https://1075.fm/wp-content/uploads/2023/03/20190314160812177065.jpg'
          },
          {
            show: 'שבת של כדורגל',
            title: 'שבת של כדורגל',
            body: 'יצאנו לדרך',
            logo: 'https://1075.fm/wp-content/uploads/2016/12/שבת-של-כדורגל.jpg'
          },
          {
            show: 'פליימייקר',
            title: 'פליימייקר',
            body: 'יצאנו לדרך',
            logo: 'https://1075.fm/wp-content/uploads/2017/08/20210917181104461648.jpg'
          },
          {
            show: 'הפאנל',
            title: 'הפאנל',
            body: 'יצאנו לדרך',
            logo: 'https://1075.fm/wp-content/uploads/2016/12/20210726154645619952.jpg'
          },
          {
            show: 'תוכנית הספורט של ישראל',
            title: 'תוכנית הספורט של ישראל',
            body: 'יצאנו לדרך',
            logo: 'https://1075.fm/wp-content/uploads/2016/12/20180529180141449101.jpg'
          }
        ]
    },
    {
        id: "megamix",
        name: "מגה מיקס",
        logo: megamixLogo,
        // episodesUrl: "https://1075.fm/api/v1/episodes?key=a6b6bf342dbf2417317ae56cb310f3a4f1097b56&limit=10&show_id=504&offset=0&igcat",
        episodesUrl: "https://1075.fm/api/v2/504",
        pages: [
          {he: "ראשי", en: "Home", icon: Icon.Type.Home},
          {he: "פושים", en: "Push", icon: Icon.Type.Notification}
        ],
        pushPages: [
          {he: "ראשי", en: "Home", icon: Icon.Type.Home}
        ],
        signal: {
          auth: 'NjY0YmIxNWQtZGVkYi00ZTljLTlhMTMtNDI4YzI0ODQ4MGFi',
          key: 'a273037a-2e77-492a-ba80-5c2c3549366e',
          logo: 'https://1075.fm/wp-content/uploads/2021/09/20210919071816817068-300x200.jpg'
        },
        fb_config: {
          apiKey: "AIzaSyAjHGeCy4tyU1eu0fNHyhav0CSN1RIDx-4",
          authDomain: "megamix---new.firebaseapp.com",
          databaseURL: "https://megamix---new-default-rtdb.europe-west1.firebasedatabase.app",
          projectId: "megamix---new",
          storageBucket: "megamix---new.appspot.com",
          messagingSenderId: "506672791931",
          appId: "1:506672791931:web:d1f88a3165f6dd2ef47dde",
          measurementId: "G-PZJDM748EX"
        }
    },
    {
      id: "hamesh",
      name: "חם אש",
      logo: hameshLogo,
      episodesUrl: 'https://www.995.co.il/api/?module=episodes',
      playlistsUrl: 'https://www.995.co.il/api/?module=playlists',
      pages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home},
        {he: "פושים", en: "Push", icon: Icon.Type.Notification},
        {he: "פופאפ", en: "Popup", icon: Icon.Type.Popup}
      ],
      pushPages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home},
        {he: "פלייליסטים", en: "Playlists", icon: Icon.Type.Play},
        {he: "לוח שידורים", en: "Schedule", icon: Icon.Type.List},
        {he: "המודעון", en: "Club", icon: Icon.Type.Shield}
      ],
      signal: {
        auth: 'NDdjNmFmOTktOTdhYy00NGJiLTlmMjEtZDg5MDQzMDM4MzYy',
        key: '260ac3b7-cbe9-41d2-a236-3411a2b163af',
        logo: 'http://www.995.co.il/wp-content/uploads/2022/06/20220601092850317938.png'
      },
      fb_config: {
        apiKey: "AIzaSyApUDzWDBVlyFBYmLKZKB9YfAcYmwX40Hk",
        authDomain: "radiohaifa-hamesh.firebaseapp.com",
        databaseURL: "https://radiohaifa-hamesh.firebaseio.com",
        projectId: "radiohaifa-hamesh",
        storageBucket: "radiohaifa-hamesh.appspot.com",
        messagingSenderId: "10101946653",
        appId: "1:10101946653:web:74463ab19778f3c96309f4",
        measurementId: "G-VEG5FEJB7P"
      }
    },
    {
      id: "radiohaifa",
      name: "רדיו חיפה",
      logo: radiohaifaLogo,
      episodesUrl: "https://1075.fm/api/v2/items/?limit=20&category=",
      categoriesUrl: "https://1075.fm/api/v2/categories/",
      pages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home},
        {he: "פושים", en: "Push", icon: Icon.Type.Notification},
        {he: "קישורים", en: "Links", icon: Icon.Type.Share}
      ],
      pushPages: [
        {he: "ראשי", en: "Home", icon: Icon.Type.Home},
        {he: "פלייליסטים", en: "Playlists", icon: Icon.Type.Play},
        {he: "תוכניות", en: "Shows", icon: Icon.Type.Play},
        {he: "פודקאסים", en: "Podcasts", icon: Icon.Type.Play}
      ],
      signal: {
        auth: 'MDRiN2QzNDItZmYyNC00MWU2LTkwY2YtNjYzNDkwYmUxZjE2',
        key: '5ed230f8-dc4f-4bf8-b2af-0bbef6d230ee',
        logo: 'https://1075.fm/wp-content/uploads/2023/05/2023-05-22_10-45-21_810287.png'
      },
      fb_config: {
        aiKey: "AIzaSyBHwWhjSVWYIOQTDvWYNvEiBnr2TN-Feps",
        authDomain: "radiohaifa-abff1.firebaseapp.com",
        databaseURL: "https://radiohaifa-abff1.firebaseio.com",
        projectId: "radiohaifa-abff1",
        storageBucket: "radiohaifa-abff1.appspot.com",
        messagingSenderId: "125064500132",
        appId: "1:125064500132:web:8de70de96ca5b3741da731",
        measurementId: "G-KC1GE3SK3Z"
      }
    }
  ]
  currentApp = firebase.apps.length? firebase.app(): null
  constructor() {
    this.init(this.projects.find(p => p.id === 'thepanel').id);
  }

  init = projectId => {
    const project = this.projects.find(p => p.id == projectId) || this.projects[1]
    this.currentApp = firebase.apps.length? firebase.app(): firebase.initializeApp(project.fb_config)
  }

  changeProject = async (projectId) => {
    const project = this.projects.find(p => p.id == projectId) || this.projects[1]
    this.currentApp = firebase.initializeApp(project.fb_config, projectId);
    firebase.app(projectId)
  }
    
  login = async(user, success_callback, failed_callback) => {
      await firebase.auth()
        .signInWithEmailAndPassword(user.email, user.password)
      .then(success_callback, failed_callback);
  }

  logout = async () => {
    await firebase.auth().signOut();
  }

  get uid() {
    return (firebase.auth().currentUser || {}).uid;
  }

  get refChat() {
    return firebase.database().ref('messages');
  }

  get refPopup() {
    return firebase.database().ref('settings/popup');
  }

  get refPushMessages() {
    return firebase.database().ref('pushes');
  }

  get refSettings() {
    return this.currentApp.database().ref('settings');
    // return firebase.database().ref('settings');
  }
  get refBusinesses() {
    return firebase.database().ref('businesses');
  }

  parse = snapshot => {
    const { createdAt: numberStamp, text, user } = snapshot.val();
    const { key: _id } = snapshot;
    const createdAt = new Date(numberStamp);
    const message = {
      _id,
      createdAt,
      text,
      user,
    };
    return message;
  };

  onNewPush = callback => 
    this.refPushMessages
      .on('child_added', snapshot => callback(snapshot.val()));

  onNewChat = callback => {
    this.refChat
      .limitToLast(20)
      .on('child_added', snapshot => callback(this.parse(snapshot)));
    }

  get createdAt() {
    return firebase.database.ServerValue.TIMESTAMP;
  }
  // send the message to the Backend
  send = messages => {
    for (let i = 0; i < messages.length; i++) {
      const { text, user } = messages[i];
      const message = {
        text,
        user,
        createdAt: this.createdAt
      };
      this.append(message);
    }
  };
  remove = msgId => this.refChat.child(msgId).remove();
  append = message => this.refChat.push(message);

  async getSettings() {
    return (await this.refSettings.once('value')).val();
  }
  getPushes() {
    return this.refPushMessages.get();
  }
  addPush = push => {
    push.date = this.createdAt;
    this.refPushMessages.push(push);
  }
  // close the connection to the Backend
  offChat() {
    this.refChat.off();
  }
  offPush() {
    this.refPushMessages.off();
  }
  offSettings() {
    this.refSettings.off();
  }
  async updateSettings(settings) {
    await this.refSettings.update(settings)
  }
  offBusinesses() {
    this.refBusinesses.off();
  }
  async addBusiness(b) {
    const a = await this.refBusinesses.push(b)
    console.log(a.path.pieces_[1])
    return a.path.pieces_[1]
  }
  async removeBusiness(key) {
    await firebase.database().ref(`businesses/${key}`).remove()
  }
  async getBusinesses() {
    const businesses = (await this.refBusinesses.once('value')).val() || {}
    const bus = []
    for (let [key, val] of Object.entries(businesses)) {
      bus.push({
        key: key,
        ...val,
        order: isNaN(val.order)? 5: val.order
      })
    }
    bus.sort((a, b) => a.order > b.order)
    return bus
  }
  async changePos(currId, currPos, prevId, prevPos) {
    await firebase.database().ref(`businesses/${currId}`).update({
      order: prevPos
    })
    await firebase.database().ref(`businesses/${prevId}`).update({
      order: currPos
    })
  }
}

Fire.shared = new Fire();
export default Fire;

import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, TouchableOpacity, Platform, Dimensions, Animated, View, Image, TouchableWithoutFeedback } from 'react-native'
import { useSelector } from 'react-redux'
import Colors from '../constants/Colors'
import Icon from '../constants/Icon'
import Layout from '../constants/Layout'
import * as RootNavigation from '../tools/RootNavigation'
import TextField from '../tools/TextField'

const dm = Dimensions.get("window")
const menuWidth = -((dm.width > Layout.Settings.DefaultMaxWidth? Layout.Settings.DefaultMaxWidth: dm.width) * 0.7)

export default function Menu({close}) {
    const position = useRef(new Animated.Value(menuWidth)).current;
    const user = useSelector(state => state.user)
    const opacity = useRef(new Animated.Value(0)).current;
    const project = useSelector(state => state.project)
    const [currentPage, setCurrentPage] = useState("Home")
    
    const onClose = () => {
        Animated.parallel([
            Animated.timing(position, {
                toValue: menuWidth,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 200
            }),
            Animated.timing(opacity, {
                toValue: 0,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 200
            })
        ]).start()
        
        setTimeout(() => {
            close()
        }, 500)
    }

    useEffect(() => {
        Animated.parallel([
            Animated.timing(position, {
                toValue: 0,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 200
            }),
            Animated.timing(opacity, {
                toValue: 1.0,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 200
            })
        ])
        .start()
        setCurrentPage(RootNavigation.current()?.name || "Home")
    }, [])

    const navTo = page => {
        RootNavigation.navigate(page)
        onClose()
    }

    if (project) {
        return (
            <TouchableOpacity onPressOut={onClose} style={styles.menuHolder}>
                <Animated.View style={[styles.menuHolder,{opacity: opacity}]}>
                    <TouchableWithoutFeedback>
                        <Animated.View style={[styles.menu, {left: position}]}>
                            <View style={{flexDirection:'row', justifyContent: 'space-between', margin: 10}}>
                                <TouchableOpacity onPress={onClose}>
                                    <Icon type={Icon.Type.ArrowForward} style={styles.x}></Icon>
                                </TouchableOpacity>
                                <Image style={styles.logo} source={project.logo}></Image>
                            </View>
                            {
                                project.pages.map((item, i) => (
                                    currentPage == item.en? (
                                        <TouchableOpacity key={i} style={[styles.page, styles.pageActive]} onPress={() => {navTo(item.en)}}>
                                            <Icon type={item.icon} style={[styles.pageIcon, styles.pageIconActive]}></Icon>
                                            <TextField text={item.he} style={[styles.pageText, styles.pageTextActive]} weight={700}></TextField>
                                        </TouchableOpacity>
                                    ): (
                                        <TouchableOpacity key={i} style={styles.page} onPress={() => {navTo(item.en)}}>
                                            <Icon type={item.icon} style={styles.pageIcon}></Icon>
                                            <TextField text={item.he} style={styles.pageText}></TextField>
                                        </TouchableOpacity>
                                    )
                                ))
                            }
                        </Animated.View>
                    </TouchableWithoutFeedback>
                </Animated.View>
            </TouchableOpacity>
        )
    } else return null
}

const styles = StyleSheet.create({
    menuHolder: {
      position:'absolute',
      right:0,
      width:dm.width,
      maxWidth: Layout.Settings.DefaultMaxWidth,
      height: dm.height,
      zIndex:1,
      backgroundColor:'rgba(0,0,0,0.85)',
      top:0,
      flexDirection:'row',
      justifyContent:'flex-start'
    },
    menu: {
        position:'absolute',
        height:'100%',
        width:dm.width*0.7,
        maxWidth: Layout.Settings.DefaultMaxWidth*0.7,
        backgroundColor:Colors.General.MainBG,
        shadowColor:Colors.General.Tab,
        shadowOffset: {
        width:0,
        height:0
        },
        shadowOpacity:0.8,
        shadowRadius:10,
        elevation:5
    },
    x: {
        alignSelf:'flex-start',
        fontSize:50,
        color:Colors.General.Text1
    },
    page: {
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        paddingVertical: 15,
        paddingHorizontal:30
    },
    pageActive: {
        backgroundColor: Colors.General.SubBg
    },
    pageText: {
        color:Colors.General.Text3,
        fontSize: 18
    },
    pageTextActive: {
        color:Colors.General.Text1,
    },
    pageIcon: {
        color:Colors.General.Text3,
        fontSize: 20,
        marginEnd:20
    },
    pageIconActive: {
        color:Colors.General.Text1,
    },
    logo: {
        width: 50,
        height: 50,
        borderRadius:100,
    }
})
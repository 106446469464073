import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Image, ActivityIndicator, Alert, Pressable } from 'react-native';
import Strings from '../constants/Strings';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import Enums from '../constants/Enums';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import megamixLogo from '../assets/images/projects/megamix.png'
import thepanelLogo from '../assets/images/projects/thepanel.png'
import radiohaifaLogo from '../assets/images/projects/radiohaifa.png'
import hameshLogo from '../assets/images/projects/hamesh.png'
import Fire from '../services/Fire';
import AuthorsAPI from '../services/AuthorsAPI';
import {useDispatch} from 'react-redux'
import { SetUser } from '../redux/app-redux';
import Storage from '../services/Storage';
import TextField from '../tools/TextField';
import AlertingService from '../services/AlertingService';
import Icon from '../constants/Icon';
import Episodes from '../components/Episodes';
import {useSelector} from 'react-redux'
import PushPreview from '../components/PushPreview';
import useBitly from '../hooks/useBitly';
import {FontAwesome5} from '@expo/vector-icons';
// import HttpService from '../services/HttpService';

const socialNetworks = [
    {
        name: 'facebook',
        color: '#1674EB'
    },
    {
        name: 'whatsapp',
        color: '#01D33C'
    },
    {
        name: 'twitter',
        color: '#1C9DEB'
    },
    {
        name: 'instagram',
        color: '#F7025E'
    },
    {
        name: 'youtube',
        color: '#F80900'
    }
]

export default function LinksScreen(props) {
    const project = useSelector(state => state.project)
    const [showEpisodes, setShowEpisodes] = useState(false)
    const [episodeSelected, setEpisodeSelected] = useState(null)
    const [source, setSource] = useState('radio_haifa')
    const [medium, setMedium] = useState(socialNetworks[0].name)
    const [campaign, setCampaign] = useState('general')
    const [bitly, setBitly] = useState('')
    const getLink = useBitly()

    const longUrl = useMemo(() => {
        if (episodeSelected === null || source === '' || medium === '' || campaign === '') return ''
        return `https://1075.fm/item/${episodeSelected.id}/?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_id=${episodeSelected.id}`
    }, [episodeSelected, source, medium, campaign])

    const closeEpisode = useCallback((episode) => {
        setShowEpisodes(false)
        setEpisodeSelected(episode)
    }, [])

    const onBitly = useCallback(async () => {
        const link = await getLink(`${longUrl}&time=${new Date().getTime()}`)
        setBitly(link)
    }, [longUrl])

    const copy = useCallback(() => {
        navigator.clipboard.writeText(bitly)
    }, [bitly])

    // const getItem = useCallback(async (id) => {
    //     const item = (await HttpService.Get(`https://1075.fm/api/v2/items?id=${id}`))[0]
    //     if (item)
    //         closeEpisode({...item, title: '', description: ''})
    // })

    return (
        <View style={styles.container}>
            <View style={{flexDirection:'row', justifyContent:'flex-start', gap: 10, width:'100%'}}>
                <Pressable onPress={() => setShowEpisodes(true)} style={styles.item}>
                    <Icon type={Icon.Type.News} style={styles.icon} />
                    <TextField weight={700} text='בחירת אייטם' />
                </Pressable>
                {
                    socialNetworks.map((network, index) => (
                        <Pressable key={index} onPress={() => setMedium(network.name)} style={[styles.item, medium === network.name? styles.itemActive: null, {backgroundColor: network.color}]}>
                            <FontAwesome5 name={network.name} size={16} style={styles.social} />
                        </Pressable>
                    ))
                }
                {/* {
                    project.id === 'radiohaifa' && (
                        <Pressable onPress={() => getItem(136750)} style={styles.item}>
                            <TextField text='אייטם אזעקות' />
                        </Pressable>
                    )
                } */}
            </View>
            <TextField text='פרטי הקישור (באנגלית בלבד)' weight={600} style={styles.title}></TextField>
            <View style={{flexDirection:'row', gap: 10, justifyContent:'space-between', width:'100%'}}>
                <InputField center value={source} onChangeText={text=>setSource(text)} multiline placeholder='המקור' />
                <InputField center value={medium} onChangeText={text=>setMedium(text)} multiline placeholder='מדיה' />
                <InputField center value={campaign} onChangeText={text=>setCampaign(text)} multiline placeholder='קמפיין' />
            </View>
            {
                episodeSelected && (
                    <View style={{width: '100%',marginBottom: 10, marginTop:5, borderRadius:5, borderWidth: 1, borderColor:'rgba(255,255,255,0.2)', backgroundColor:'rgba(255,255,255,0.1)'}}>
                        <PushPreview data={{title: episodeSelected.title, description: episodeSelected.description, logo: episodeSelected.logo}} />
                    </View>
                )
            }
            <ButtonField disabled={longUrl === ''} style={{width: '32.35%'}} title='יצירת לינק מקוצר' onPress={onBitly} weight={700}></ButtonField>
            {
                bitly != '' && (
                    <Pressable onPress={copy} style={{width: '100%',marginTop:5, padding: 10, borderRadius:5, borderWidth: 1, borderColor:'rgba(255,255,255,0.2)', backgroundColor:'rgba(255,255,255,0.1)', justifyContent:'center', alignContent:'center'}}>
                        <TextField text={bitly} center weight={600} style={styles.bitly}></TextField>
                    </Pressable>
                )
            }
            {
                showEpisodes? <Episodes close={closeEpisode} project={project} />: null
            }
        </View>
    );
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor:Colors.General.SubBg,
    padding: 10,
    paddingVertical: 30,
    flexDirection:'column',
    width:'100%',
    height:'100%'
  },
  title: {
    marginTop: 10,
    color:Colors.General.Text1,
    fontSize:16,
    alignSelf: 'flex-start',
  },
  bitly: {
    color:Colors.General.Text1,
    fontSize:24,
    alignSelf:'center'
  },
  items: {
    width:'100%',
    marginVertical: 1
},
item: {
    marginTop: 5,
    marginEnd:5,
    marginBottom: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius:5,
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.2)',
    justifyContent:'center',
    alignItems:'center',
    padding: 5,
    paddingHorizontal: 15,
    flexDirection:'row'
},
itemActive: {
    borderColor:'rgba(255, 255, 255, 1)',
},
iconHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    width:22, height:22, marginBottom: 5
  },
  icon: {
    fontSize: 16,
    marginEnd: 5
  },
  social: {
    color:'#fff'
  }
})

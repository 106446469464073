import Constants from 'expo-constants';
import HttpService from './HttpService';
import Fire from './Fire';

export default class SettingsAPI {
    static Settings = null;
   
    static Get = async () => {
        // if (SettingsAPI.Settings) 
        //     return SettingsAPI.Settings;
        SettingsAPI.Settings = await Fire.shared.getSettings();
        // SettingsAPI.Settings = await HttpService.GetFromServer(Constants.manifest.extra.api.radiohead.request.settings);
        return SettingsAPI.Settings;
    }

    static Save = async () => {
        await Fire.shared.updateSettings(SettingsAPI.Settings);
        Fire.shared.offSettings();
        // await HttpService.Post(Constants.manifest.extra.api.radiohead.request.settings, SettingsAPI.Settings);
    }
}
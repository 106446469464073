const General = {
  Loading: "טוען...",
  Soon: "בקרוב...",
  RadioHaifa: "רדיו חיפה",
  Loading: "טוען",
  PanelSystem: "מערכת ספורט רדיו חיפה",
  Live: "LIVE",
  Confirm: "אישור",
  Cancel: "ביטול",
  Thanks: "אחלה"
}
const Login = {
  Login: "התחבר",
  AlertErrorTitle: "לא התחבר",
  AlertErrorDesc: "אחד הפרטים שגויים",
  Thanks: "הבנתי",
  Email: "דוא\"ל",
  Password: "סיסמא",
  Logout: "התנתק",
  Hello: "שלום",
  Title: "מערכת ניהול אפליקציות"
}
const Menu = {
  Home: "בית",
  Push: "פושים",
  Chat: "צ'ט",
  Settings: "הגדרות",
  Popup: "פופאפ",
  Businesses: "עסקים",
  Links: "קישורים"
}
const Push = {
  Screen: "ניהול הודעות פוש",
  Title: "שליחת הודעת פוש חדשה",
  Send: "שלח פוש",
  NoPushes: "אין הודעות פוש שנשלחו",
  Pushes: "הודעות פוש אחרונות",
  AlertTitle: "שליחת פוש",
  AlertError: "חובה להזין תוכן פוש",
  AlertDesc: "\nהאם אתה בטוח שברצונך לשלוח פוש זה?\nאין דרך חזרה...",
  AlertOK: "כן, שלח",
  AlertCancel: "ביטול",
  Successful: "הודעת פוש נשלחה בהצלחה",
  Error: "ארעה שגיאה בשליחת הודעת הפוש",
  Thanks: "אחלה",
  Direct: "הפנייה",
  ToUsers: "לאיזה יוזרים לשלוח",
  Preview: "תצוגה מקדימה",
  SentTo: "נשלח ל-",
  Devices: "סה״כ",
  SelectEpisode: "בחירת קטע",
  Episodes: "אייטם",
  Playlists: "פלייליסט",
  PlaceHolderTitle: "כותרת הפוש",
  PlaceHolderDate: "בחירת תאריך",
  PlaceHolderBody: "תוכן הפוש",
  PlaceHolderLogo: "כתובת תמונה",
  From: "מתוך",
  Failed: "נכשלו",
  Converted: "הקליקו"
}
const Popup = {
  Screen: "ניהול פופאפים",
  Title: "הקפצת פופאפ חדש (חובה רק אם מפנים לאייטם או פלייליסט)",
  Send: "שמירת פופאפ",
  Cancel: "הפסקת פופאפ",
  AlertTitle: "שמירת פופאפ",
  AlertError: "כל השדות הינם חובה",
  AlertDesc: "\nהאם אתה בטוח שברצונך לשמור פופאפ זה?\nאין דרך חזרה...",
  AlertOK: "כן, שמור",
  AlertCancel: "ביטול",
  Successful: "פופאפ נשמר בהצלחה",
  Error: "ארעה שגיאה בשמירת הפופאפ",
  Thanks: "אחלה",
  ToUsers: "לאיזה יוזרים לשלוח",
  Direct: "הפנייה",
  Freq: "תדירות (כל כמה כניסות יראו את הפופאפ)",
  Preview: "תצוגה מקדימה",
  SelectEpisode: "בחירת קטע",
  Episodes: "אייטם",
  Playlists: "פלייליסט",
  PlaceHolderTitle: "כותרת הפופאפ",
  PlaceHolderFreq: "תדירות",
  PlaceHolderLogo: "כתובת תמונה",
}
const Chat = {
  PlaceHolder: "תוכן הודעה",
  Login: "התחברות כתבים",
  Email: "דוא\"ל",
  Password: "סיסמא",
  LoginButton: "התחבר",
  RemoveTitle: "מחיקת ההודעה של",
  RemoveDesc: "לא יהיה ניתן לשחזר הודעה זו...",
  RemoveOk: "מחק הודעה",
  RemoveCancel: "ביטול",
  Copy: "העתק",
  an: "אוהד",
  of: "של",
  Author: "עורך ההודעה",
  SelectAuthor: "החלף כתב",
  Send: "שליחה",
  Push: "הוסף הודעת פוש עם הפנייה לעמוד הצ׳ט",
  PushTitle: "צ׳ט כתבים",
  Sending: "שולח הודעה..."
}
const Settings = {
  General: "הגדרות כלליות",
  Streams: "כתובות סטרים",
  Audio: "כתובת אודיו",
  Megamix: "כתובת מגהמיקס",
  Video: "כתובת וידאו",
  Tabs: "הצגת טאבים",
  TabHome: "ראשי",
  TabChat: "צ׳ט כתבים",
  TabLive: "שידור לייב",
  TabWhatsapp: "וואטסאפ",
  TabShows: "תוכניות",
  Whatsapp: "מספר וואטסאפ",
  ItemsSettings: "הגדרות אייטמים",
  Items: "כמות חדשות וקטעים מתוכניות",
  FullShows: "כמות תוכניות מלאות",
  Chat: "כמות הודעות צ׳ט אחרונות",
  Save: "שמור שינויים",
  AlertTitle: "שמירת שינויים",
  AlertDesc: "האם אתה בטוח שברצונך לשמור שינויים אלה?",
  AlertCancel: "ביטול",
  AlertOK: "חיובי, שמור",
  Successful: "השינויים עודכנו בהצלחה",
  ShowDesc: "הצגת תיאור אייטם בחדשות",
  ShowWebview: "הצגת התוכן המלא מהאתר",
  AndroidVersion: "אנדרואיד",
  IOSVersion: "אייפון",
  MinVersion: "גירסה מינימלית"
}
const Titles = {
  Home: "בית",
  Chat: "צ׳ט כתבים",
  Push: "ניהול פושים",
  Settings: "הגדרות"
}

    export default {
      General,
      Menu,
      Push,
      Login,
      Settings,
      Chat,
      Titles,
      Popup
    }
  
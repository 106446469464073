import Constants from 'expo-constants';

export default class HttpService {
  static GetFromServer = async (url) => {
    url = Constants.manifest.extra.api[__DEV__? "test": "radiohead"].url + url;
    return await HttpService.Get(url);
  }
  static GetFromClientServer = async (url, limit) => {
    url = Constants.manifest.extra.api.radiohaifa.url + url + "?limit=" + (limit || Constants.manifest.extra.api.defaultLimit) + "&key=" + Constants.manifest.extra.api.radiohaifa.key;
    const result = await HttpService.Get(url);
    return result
  }
  static Get = async (url) => {
    try {
        let response = await fetch(url);
        let responseJson = await response.json();
        return responseJson;
      } catch (error) {
        console.error(error);
      }
  }
  static Post = async (url, data, method) => {
    url = Constants.manifest.extra.api[__DEV__? "test": "radiohead"].url + url
    return await fetch(url, {
      method: method || 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    })
  }
}
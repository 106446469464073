const zero = (a) => {
    return a<10? "0" + a:a;
  }
  const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי","יוני","יולי","אוגוסט","ספטמבר","אוקטובר","נובמבר","דצמבר"];

  
  export default class DateFilter {
    static Date = (d) => {
      d = new Date(d);
      return d.getDate() + " ב" + months[d.getMonth()] + " " + d.getFullYear()
    }

    static DateTime = (d) => {
        d = new Date(d);
        return d.getDate() + " ב" + months[d.getMonth()] + " " + d.getFullYear() + ", " + zero(d.getHours()) + ":" + zero(d.getMinutes());
      }
  
    static Time = (d) => {
      d = new Date(d);
      return zero(d.getHours()) + ":" + zero(d.getMinutes());
    }
    
    static DayTime = (d) => {
      d = new Date(d)
        let day;
      switch(d.getDay()) {
          case 1:
            day = "ראשון";
            break;
          case 2:
              day = "שני";
            break;
          case 2:
              day = "שלישי";
            break;
          case 2:
              day = "רביעי";
            break;
          case 2:
              day = "חמישי";
            break;
          case 2:
              day = "שישי";
            break;
          case 2:
              day = "שבת";
            break;
          default:
              day = "ראשון";
              break;
        }
      return day + " " + DateFilter.Time(d);
    }
  
    static PostTime = (d) => {
      d = new Date(d);
      var diff = (((new Date()).getTime() - d.getTime()) / 1000);
      var day_diff = Math.floor(diff / 86400);
              
      if ( isNaN(day_diff) || day_diff < 0 || day_diff >= 31 )
          return;
              
      return day_diff == 0 && (
              diff < 60 && "עכשיו" ||
              diff < 120 && "לפני דקה" ||
              diff < 3600 && "לפני " + Math.floor( diff / 60 ) + " דקות" ||
              diff < 7200 && "לפני כשעה" ||
              diff < 86400 && "לפני " + Math.floor( diff / 3600 ) + " שעות") ||
          day_diff == 1 && "אתמול" ||
          day_diff < 7 && "לפני " + day_diff + " ימים" ||
          day_diff < 31 && "לפני " + Math.ceil( day_diff / 7 ) + " שבועות";
    }

    static MessageTime = (d) => { 
      d = new Date(d);
      var diff = (((new Date()).getTime() - d.getTime()) / 1000);
      var day_diff = Math.floor(diff / 86400);
    
      if ( isNaN(day_diff) || day_diff < 0 )
        return;
    
      if (day_diff == 1)
        return "אתמול";
      else if (day_diff == 2)
        return "שלשום";
    
      else if (day_diff > 2)
        return d.getDate() + " ב" + months[d.getMonth()] + ", " + d.getFullYear();
      }
  }
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import Enums from '../constants/Enums';

export default function TextField(props) {

  return (
    
    <Text style={[styles.text, {fontFamily: 'AppFont' + (props.weight || 400)}, props.style, props.underline?styles.underline:null]} ellipsizeMode={'tail'} numberOfLines={props.lines?props.lines:0}>{props.text}</Text>
  )
  
}

const styles = StyleSheet.create({
  text: {
    textAlign:'left',
    color:'#fff'
  },
  underline: {
    textDecorationLine: 'underline'
  }
});

import React from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'
import Icon from '../constants/Icon'
import DateFilter from '../tools/DateFilter'
import TextField from '../tools/TextField'
export default function BusinessPreview({item: business, index, onRemove, onChangePos}) {
    if (!business) return null
    return (
        <View style={styles.container}>
            <View>
                <Pressable onPress={() => onChangePos(business.key, index, 1)}>
                    <Icon type={Icon.Type.Up} style={styles.x} />
                </Pressable>
                <Pressable onPress={() => onChangePos(business.key, index, -1)}>
                    <Icon type={Icon.Type.Down} style={styles.x} />
                </Pressable>
            </View>
            <Image source={{uri: business.logo}} style={styles.logo} />
            <TextField weight={700} text={business.order} style={styles.name}></TextField>
            <View style={styles.content}>
                <TextField weight={700} text={business.name} style={styles.name}></TextField>
                <TextField weight={400} text={business.sale} style={styles.sale}></TextField>
                <TextField weight={400} text={`תוקף: ${DateFilter.Date(new Date(business.expire))}`} style={styles.date}></TextField>
            </View>
            <Pressable onPress={onRemove}>
                <Icon type={Icon.Type.X} style={styles.x} />
            </Pressable>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'rgba(255,255,255,0.1)',
        flexDirection:'row',
        borderRadius:10,
        borderWidth:1,
        borderColor: 'rgba(255,255,255,0.1)',
        alignItems:'center'
    },
    content: {
        flex: 1
    },
    logo: {
        aspectRatio:16/9,
        width: 100,
        borderRadius:5,
        margin: 5,
        marginEnd: 20
    },
    name: {
        color: Colors.General.Text1,
        fontSize:14
    },
    sale: {
        color: Colors.General.Text1,
        fontSize:12,
        marginVertical: 1
    },
    date: {
        color: Colors.General.Text3,
        fontSize:12
    },
    x: {
        fontSize:20,
        padding: 10
    }
})

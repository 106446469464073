import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, ActivityIndicator, FlatList } from 'react-native';
import Colors from '../constants/Colors';
import TextField from '../tools/TextField';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import Fire from '../services/Fire';
import BusinessPreview from '../components/BusinessPreview';
const d = new Date()
d.setHours(0); d.setMinutes(0); d.setSeconds(0); d.setMilliseconds(0)
const zero = a => a > 10? a: '0' + a
export default function BusinessesScreen(props) {
    const [businesses, setBusinesses] = useState([])
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState(__DEV__? 'סתם עסק': '')
    const [address, setAddress] = useState(__DEV__? 'סתם רחוב': '')
    const [phone, setPhone] = useState(__DEV__? '5555': '')
    const [logo, setLogo] = useState(__DEV__? 'https://1075.fm/wp-content/uploads/2023/03/20230309104220447843.jpeg': '')
    const [sale, setSale] = useState(__DEV__? '100% הנחה לכל הרוכשים': '')
    const [selectedDate, setSelectedDate] = useState(`${d.getFullYear()}-${zero(d.getMonth() + 1)}-${zero(d.getDate())}T${zero(d.getHours())}:${zero(d.getMinutes())}`)

    const save = useCallback(async () => {
        if (loading) return
        setLoading(true)
        const b = { name, address, phone, logo, sale, expire: new Date(selectedDate).getTime(), added: new Date().getTime() }
        const key = Fire.shared.addBusiness(b)
        setBusinesses(prev => {
            prev.unshift({...b, key})
            return prev
        })
        setTimeout(() => setLoading(false), 1000)
    }, [name, address, phone, logo, sale, selectedDate, loading])

    const onRemove = useCallback((key) => {
        setBusinesses(prev => prev.filter(b => b.key != key))
        Fire.shared.removeBusiness(key)
    }, [businesses])

    useEffect(() => {
        Fire.shared.getBusinesses().then(b => {
            setBusinesses(b)
            setLoading(false)
        })
    }, [])

    const changePos = useCallback(async (key, index, change) => {
        if (loading) return
        if (index === 0 && change < 0) return
        if (index === businesses.length - 1 && change > 1) return
        setLoading(true)
        
        const temp = businesses
        console.log(temp)
        const tempItem = temp[index+change]
        temp[index+change] = {...temp[index], order: index}
        temp[index] = {...tempItem, order: index+change}
        console.log(temp)
        setBusinesses(temp.sort((a, b) => a.order > b.order))
        // if ()
        // await Fire.shared.changePos()
        console.log(key, index, change);
        setLoading(false)
    }, [loading, businesses])

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <TextField text='הוספת עסק' weight={800} style={styles.title}></TextField>
                <View style={{flexDirection:'row', gap: 20}}>
                    <InputField rtl value={name} onChangeText={text=>setName(text)} placeholder='שם העסק' />
                    <InputField rtl value={phone} onChangeText={text=>setPhone(text)} placeholder='מספר טלפון' />
                </View>
                <View style={{flexDirection:'row', gap: 20}}>
                    <InputField rtl value={address} onChangeText={text=>setAddress(text)} placeholder='כתובת' />
                    <InputField rtl value={sale} onChangeText={text=>setSale(text)} placeholder='פירוט ההטבה' />
                </View>
                <View style={{flexDirection:'row', gap: 20}}>
                    <InputField rtl value={logo} onChangeText={text=>setLogo(text)} placeholder='כתובת לוגו' />
                    <input type='date' value={selectedDate} onChange={e=>setSelectedDate(e.target.value)} style={{ backgroundColor:'#fff', borderRadius:5, padding:10, fontSize:16, margin:'5px 0 10px', width: '40%' }} />
                </View>
            </View>
            <ButtonField title='שמירת עסק' onPress={save} />
            {
                loading? (
                    <ActivityIndicator size='large' color={'gray'} />
                ): (
                    businesses.map((item, index) => (
                        <BusinessPreview item={item} onChangePos={changePos} index={index} onRemove={() => onRemove(item.key)} />
                    ))
                    // <FlatList
                    //     style={{marginVertical:20}}
                    //     data={businesses}
                    //     extraData={businesses}
                    //     keyExtractor={(item, index) => index.toString()}
                    //     renderItem={({item, index}) => <BusinessPreview item={item} onChangePos={changePos} index={index} onRemove={() => onRemove(item.key)} />}
                    //     ItemSeparatorComponent={() => <View style={{width:'10px',height:'10px'}} />}
                    //     />
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.General.SubBg,
        padding: 20
    },
    title: {
        color:Colors.General.Text1,
        fontSize:20,
        alignSelf: 'flex-start',
    },
    date: {
        backgroundColor:'#fff',
        borderRadius:5,
        padding:10,
        fontSize:16,
        margin:'5px 0 10px'
    }
})
import React from 'react';
import { StyleSheet, View, Modal, SafeAreaView,TouchableOpacity, ActivityIndicator, ScrollView  } from 'react-native';
import Strings from '../constants/Strings';
import TextField from '../tools/TextField';
import Colors from '../constants/Colors';
import Icon from '../constants/Icon';
import EpisodeItem from '../components/EpisodeItem';
import useFetch from '../hooks/useFetch';
import Layout from '../constants/Layout';

export default function Playlists(props) {
    const {data: playlists, loading} = useFetch(props.project.playlistsUrl);

    const close = () => {
        props.close(null);
    }
    return (
        <Modal
        animationType="slide"
        transparent={true}
        presentationStyle="pageSheet"
        visible={true}
        onRequestClose={close}>
            <SafeAreaView style={styles.container}>
            <View style={styles.modalFrame}>
                <View style={styles.modalHolder}>
                    <View style={styles.modalHeader}>
                        <TextField text={Strings.Push.SelectEpisode} style={styles.title}></TextField>
                        <TouchableOpacity onPress={close}>
                            <Icon type={Icon.Type.X} style={styles.x} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.modalContent}>
                        {
                            loading? (
                                <ActivityIndicator size='large' color={Colors.General.Text1} />
                            ): playlists && playlists.length>0? (
                                <ScrollView>
                                    {
                                        playlists.map((item, i) => (
                                            <EpisodeItem onSelect={props.close} key={i} episode={item} ></EpisodeItem>
                                        ))
                                    }
                                </ScrollView>
                            ): null
                        }
                    </View>
                </View>
            </View>
            </SafeAreaView>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        width:'100%',
        maxWidth: Layout.Settings.DefaultMaxWidth,
        flex: 1,
        backgroundColor: Colors.General.SubBg,
        alignSelf:'center'
      },
    modalFrame:{
        flex: 1,
        backgroundColor:Colors.General.MainBG,
      },
      modalHolder:{
        alignItems: 'center',
        backgroundColor:Colors.General.MainBG,
        justifyContent: 'flex-start',
        width:'100%',
        height:'100%'
      },
      modalHeader: {
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        paddingHorizontal: 10,
      },
      modalContent:{
        flex:1,  
        backgroundColor:Colors.General.SubBg,
        width:'100%',
        padding:10
      },
      x: {
        color:Colors.General.Text2, 
        fontSize:28,
        marginStart:10,
        paddingVertical: 15,
      },
      title: {
        color:Colors.General.Text2,
        fontSize:20,
    },
});

const IconType = {
  Ionicons: 1,
  MaterialCommunityIcons: 2,
  AntDesign: 3,
  MaterialIcons: 4,
  Feather: 5,
  SimpleLineIcons: 6,
  Entypo: 7,
  FontAwesome: 8,
  EvilIcons: 9
}
const LoginStatus = {
  Yes: 1,
  Loading: 2,
  No: 3
}
const PushStatus = {
  Yes: 1,
  Loading: 2,
  No: 3
}
const PopupStatus = {
  Yes: 1,
  Loading: 2,
  No: 3
}
const UserRank = {
  Admin: 1,
  Regular: 10
}
const PushMode = {
  Page: 1,
  Episode: 2,
  Playlist: 3
}
const PopupMode = {
  Page: 1,
  Episode: 2,
  Playlist: 3
}
const Skeleton = {
  MainArticle: 1,
  EpisodeList: 2,
  ChatMessage: 3
}
const ButtonTypes = {
  Small: 1,
  Regular: 2
}
  export default {
    IconType,
    LoginStatus,
    PushStatus,
    UserRank,
    PushMode,
    Skeleton,
    ButtonTypes,
    PopupMode,
    PopupStatus
  }

import React, { useEffect } from 'react'
import { View } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import HomeScreen from './screens/HomeScreen'
import ChatScreen from './screens/ChatScreen'
import PushScreen from './screens/PushScreen'
import SettingsScreen from './screens/SettingsScreen'
import * as RootNavigation from './tools/RootNavigation'
import { useSelector } from 'react-redux'
import PopupScreen from './screens/PopupScreen'
import BusinessesScreen from './screens/BusinessesScreen'
import LinksScreen from './screens/LinksScreen'

const Stack = createStackNavigator();

export default function NavigationHolder() {
  const project = useSelector(state => state.project)

  useEffect(() => {
    RootNavigation.navigate("Home")
  }, [project.id])
  
  return (
    <View style={{flex:1,width:'100%',flexDirection:'row'}}>
      <NavigationContainer ref={RootNavigation.navigationRef}>
        <Stack.Navigator initialRouteName="Home" screenOptions={{headerShown:false}}>
          <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="Chat" component={ChatScreen} />
          <Stack.Screen name="Push" component={PushScreen} />
          <Stack.Screen name="Popup" component={PopupScreen} />
          <Stack.Screen name="Links" component={LinksScreen} />
          <Stack.Screen name="Settings" component={SettingsScreen} />
          <Stack.Screen name="Businesses" component={BusinessesScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}
import React, {useEffect, useRef} from 'react';
import { StyleSheet, View, Animated, Platform } from 'react-native';
import Colors from '../constants/Colors';
import Enums from '../constants/Enums';
import Layout from '../constants/Layout';

export default function Skeleton(props) {
    const animatedOpacity = useRef(new Animated.Value(1)).current;

    const start = () => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(animatedOpacity, {
                toValue: 0.9,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 500
                }),
                Animated.timing(animatedOpacity, {
                toValue: 0.5,
                useNativeDriver: Platform.OS == "web"? false: true,
                duration: 500
                }),
            ])
        ).start();
    }

    useEffect(() => {
        start();

        return () => {
            animatedOpacity.stopAnimation();
        }
    }, []);
    
    if (props.type == Enums.Skeleton.MainArticle) {
        return (
            <View style={[styles.wrapper, {backgroundColor:Colors.Skeleton.BG, paddingVertical:Layout.Settings.MarginNormal*2}]}>
                <Animated.View style={{opacity: animatedOpacity}}>
                    <View style={[styles.img, styles.imgBig]}></View>
    
                    <View style={[styles.row, styles.rowBig]}></View>
                    <View style={[styles.row, styles.rowBig, styles.rowLast]}></View>
    
                    <View style={[styles.row, styles.rowSmall]}></View>
                    <View style={[styles.row, styles.rowSmall]}></View>
                    <View style={[styles.row, styles.rowSmall]}></View>
                    <View style={[styles.row, styles.rowSmall, styles.rowLast]}></View>
                </Animated.View>
            </View>
        );
    } else if (props.type == Enums.Skeleton.EpisodeList) {
        return (
            <View style={styles.wrapper}>
                <Animated.View style={{opacity: animatedOpacity}}>
                    <View style={{flexDirection:'row'}}>
                        <View style={[styles.img, styles.imgSmall]}></View>
                        <View style={{width:'60%', justifyContent:'center'}}>
                            <View style={[styles.row, styles.rowTitleMedium]}></View>
                            <View style={[styles.row, styles.rowTitleMedium, styles.rowLast]}></View>    

                            <View style={[styles.row, styles.rowDescMedium]}></View>
                            <View style={[styles.row, styles.rowDescMedium, styles.rowLast]}></View>
                        </View>
                    </View>
                </Animated.View>
            </View>
        )
    } else if (props.type == Enums.Skeleton.ChatMessage) {
        return (
            <View style={[styles.wrapper, styles.chatWrapper]}>
                <View style={styles.chatContent}>
                    <Animated.View style={{opacity: animatedOpacity}}>
                        <View style={styles.chatImg}></View>
                        <View style={styles.chatAuthor}></View>
                        <View style={styles.chatText}></View>
                        <View style={styles.chatText}></View>
                        <View style={[styles.chatText, {width:'80%'}]}></View>
                        <View style={styles.chatTime}></View>
                    </Animated.View>
                </View>
            </View>
        )
    }
    else return null;
    
}
const styles = StyleSheet.create({
    wrapper: {
        padding:Layout.Settings.MarginNormal,
    },
    img: {
        backgroundColor:Colors.Skeleton.Row, 
        borderRadius:7
    },
    imgBig: {
        width:'100%',
        height: 200,
        marginBottom:Layout.Settings.MarginBig,
    },
    imgSmall: {
        width: 125,
        height: 100,
        marginEnd:Layout.Settings.MarginNormal
    },
    row: {
        backgroundColor:Colors.Skeleton.Row, 
        width:'100%',
        marginBottom:Layout.Settings.MarginNormal,
        borderRadius:7
    },
    rowBig: {
        height:Layout.Settings.MarginNormal * 2, 
    },
    rowSmall: {
        height:Layout.Settings.MarginNormal, 
    },
    rowLast: {
        width:'80%'
    },
    rowTitleMedium: {
        height: Layout.Settings.MarginNormal,
    },
    rowDescMedium: {
        height: Layout.Settings.MarginNormal,
        opacity: 0.5
    },
    chatWrapper: {
        position:'relative',
        marginTop:20
    },
    chatContent: {
        borderRadius:7,
        backgroundColor:Colors.Skeleton.Row,
        marginStart:30,
        width:'90%',
        padding:10,
        paddingStart:35
    },
    chatImg: {
        position:'absolute',
        left:-60,
        top:-30,
        width:50,
        height:50,
        borderRadius:100,
        backgroundColor:Colors.Skeleton.RowDark
    },
    chatAuthor: {
        backgroundColor:Colors.Skeleton.RowDark,
        height:10,
        borderRadius:7,
        marginBottom:10,
        width:100
    },
    chatText: {
        backgroundColor:Colors.Skeleton.RowDark,
        height:10,
        borderRadius:7,
        marginBottom:10,
        width:'100%'
    },
    chatTime: {
        backgroundColor:Colors.Skeleton.RowDark,
        height:10,
        borderRadius:7,
        width:'30%',
        alignSelf:'flex-end'
    }
});
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Enums from '../constants/Enums';
import Icon from '../constants/Icon';
import TextField from '../tools/TextField';

export default function ButtonField({title, disabled, weight = 400, style, icon, onPress, type = Enums.ButtonTypes.Regular}) {
  return (
    <TouchableOpacity
      title={title}
      onPress={onPress}
      disabled={disabled}
      style={[styles.button, type == Enums.ButtonTypes.Small? styles.buttonSmall: null, {fontFamily: 'AppFont' + weight}, style, disabled? styles.disabled: null]}>
        {
          icon? (
            <Icon type={icon} style={[styles.icon, type == Enums.ButtonTypes.Small? styles.iconSmall: null]}></Icon>
          ): null
        }
        {
          title? (
            <TextField style={[styles.text, type == Enums.ButtonTypes.Small? styles.textSmall: null]} text={title} weight={600}></TextField>
          ): null
        }
    </TouchableOpacity>
  )
  
}

const styles = StyleSheet.create({
  container:{
    
  }, 
  button: {
    flexDirection:'row',
    backgroundColor:Colors.General.Button,
    paddingVertical:9,
    justifyContent:'center',
    alignContent:'center',
    borderRadius:5,
    marginVertical:6
  },
  disabled: {
    opacity:0.5
  },
  buttonSmall: {
    paddingVertical:3,
    marginVertical:5
  },
  text: {
    color:'#fff',
    fontSize:16,
    marginHorizontal:3
  },
  textSmall: {
    fontSize: 13
  },
  icon: {
    color:Colors.General.Text1,
    fontSize:16,
    marginTop:3,
    marginHorizontal:3
  },
  iconSmall: {
    fontSize: 13,
    marginTop:2,
    marginHorizontal:2
  }
});

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image, ActivityIndicator, Alert, Dimensions } from 'react-native';
import Strings from '../constants/Strings';
import InputField from '../tools/InputField';
import ButtonField from '../tools/ButtonField';
import Enums from '../constants/Enums';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import megamixLogo from '../assets/images/projects/megamix.png'
import thepanelLogo from '../assets/images/projects/thepanel.png'
import radiohaifaLogo from '../assets/images/projects/radiohaifa.png'
import hameshLogo from '../assets/images/projects/hamesh.png'
import Fire from '../services/Fire';
import AuthorsAPI from '../services/AuthorsAPI';
import {useDispatch} from 'react-redux'
import { SetUser } from '../redux/app-redux';
import Storage from '../services/Storage';
import TextField from '../tools/TextField';
import AlertingService from '../services/AlertingService';

export default function LoginScreen(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const login = () => {
        setLoading(true);
        Fire.shared.login({email: email.toLowerCase(), password: password}, successLogin, failedLogin);
    }
    const successLogin = async () => {
        const author = AuthorsAPI.GetByEmail(email);
        const user = {_id: Fire.shared.uid, email: email, name: author.name, id: author.id, avatar: author.avatar, rank: author.rank}
        await Storage.SetUser(user);
        setLoading(false)
        dispatch(SetUser(user))
    }
    const failedLogin = () => {
        setLoading(false)
        AlertingService.Alert(Strings.Login.AlertErrorTitle, Strings.Login.AlertErrorDesc)
    }
    useEffect(() => {
        Storage.GetUser().then(u => {
            setTimeout(() => {
                setLoading(false)
                if (u)
                    dispatch(SetUser(u))
            }, 1000)
        });
    }, [])

    return (
        <View style={styles.container}>
            <TextField text={Strings.Login.Title} style={styles.title} weight={700}></TextField>
            <View style={styles.logosHolder}>
                <Image source={thepanelLogo} style={styles.logo}></Image>
                <Image source={megamixLogo} style={styles.logo}></Image>
                <Image source={radiohaifaLogo} style={styles.logo}></Image>
                <Image source={hameshLogo} style={styles.logo}></Image>
            </View>
            {
                loading? (
                    <ActivityIndicator size='large' color={'gray'} />
                ): (
                    <View style={{width:'100%'}}>
                        <InputField center value={email} onChangeText={text=>setEmail(text)} placeholder={Strings.Login.Email} keyboardType="email-address"></InputField>
                        <InputField center value={password} onChangeText={text=>setPassword(text)} placeholder={Strings.Login.Password} textContentType="password" secureTextEntry={true} submit={login}></InputField>
                        <ButtonField title={Strings.Login.Login} onPress={login}></ButtonField>
                    </View>
                )
            }
        </View>
    );
}
const logoSize = Dimensions.get("window").width / 5.5

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.General.MainBG,
    width:'90%',
    alignSelf:'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
    button: {
        backgroundColor:Colors.General.Link,
        color:'red'
  },
  title: {
    color:Colors.General.Text2,
    fontSize:30
  },
  logosHolder: {
    flexDirection:'row',
    alignItems:'center',
    marginVertical:20
  },
  logo: {
        width: logoSize,
        height: logoSize,
        maxWidth: 120,
        maxHeight: 120,
        borderRadius:60,
        marginHorizontal:10,
  }
});

import { Dimensions } from 'react-native';

const Window = {
    Width: Dimensions.get('window').width,
    Height: Dimensions.get('window').height,
};
const Settings = {
    DefaultMaxWidth: 600,
    BorderRadius: 5,
    MarginBig: 20,
    MarginNormal: 10,
    MarginSmall: 5
}

export default {
    Window,Settings
};

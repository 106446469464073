import React from 'react';
import { Platform, StyleSheet, TextInput } from 'react-native';
import Strings from '../constants/Strings';

export default function InputField(props) {

  return (
    <TextInput 
    {...props}
    onSubmitEditing={props.submit? props.submit: () => {}}
    value={props.value}
    placeholder={props.placeholder || Strings.Chat.PlaceHolder}
    onChangeText={text=>props.onChangeText(text)}
    style={[styles.text, {fontFamily: 'AppFont' + (props.weight || 400)}, props.ltr? styles.ltr: styles.rtl, props.center? styles.center: null, props.style]}></TextInput>
  )
}

const styles = StyleSheet.create({
  text: {
    backgroundColor:'#fff',
    borderRadius:5,
    paddingVertical:10,
    paddingHorizontal:10,
    width:'100%',
    fontSize:16,
    marginVertical:10
  },
  rtl: {
    textAlign:Platform.OS == 'web'? 'left': 'right',
    writingDirection:'rtl',
  },
  ltr: {
    textAlign:Platform.OS == 'web'? 'right': 'left',
    writingDirection:'ltr',
  },
  center: {
    textAlign:'center'
  }
});

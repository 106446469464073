import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import React, { useEffect, useState } from 'react';
import { StyleSheet, I18nManager, LogBox, View, SafeAreaView, StatusBar, KeyboardAvoidingView, Platform } from 'react-native';
import Enums from './constants/Enums';
import LoginScreen from './screens/LoginScreen';
import Colors from './constants/Colors';
import Header from './components/Header';
import NavigationHolder from './NavigationHolder';
import AuthorsAPI from './services/AuthorsAPI';
import SettingsAPI from './services/SettingsAPI';
import {Provider, useSelector, useDispatch} from 'react-redux'
import {SetProject, Store } from './redux/app-redux'
import Storage from './services/Storage';
import Fire from './services/Fire';
import Layout from './constants/Layout';

if (Platform.OS != 'web') {
  LogBox.ignoreLogs([
    'Non-serializable values were found in the navigation state',
    'Native splash screen is already hidden.',
    'VirtualizedLists should never be nested inside plain ScrollViews with the same orientation'
  ]);
}

I18nManager.allowRTL(true);
I18nManager.forceRTL(true);

const init = async () => {
  await Font.loadAsync({
    'AppFont800': require('./assets/fonts/assistant-v7-hebrew-800.ttf'),
    'AppFont700': require('./assets/fonts/assistant-v7-hebrew-700.ttf'),  
    'AppFont600': require('./assets/fonts/assistant-v7-hebrew-600.ttf'),
    'AppFont500': require('./assets/fonts/assistant-v7-hebrew-500.ttf'),
    'AppFont400': require('./assets/fonts/assistant-v7-hebrew-400.ttf'),
    'AppFont300': require('./assets/fonts/assistant-v7-hebrew-300.ttf'),
    'AppFont200': require('./assets/fonts/assistant-v7-hebrew-200.ttf')
  })
  await SettingsAPI.Get();
  await AuthorsAPI.Get();
}

function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
  const user = useSelector(state => state.user)
  const project = useSelector(state => state.project)
  const dispatch = useDispatch()

  useEffect(() => {
    Storage.GetProject().then(projectId => {
        const project = Fire.shared.projects.find(p => p.id == projectId) || Fire.shared.projects[1]
        dispatch(SetProject(project))
    })
  }, [])

  if (!fontLoaded) {
    return (
    <AppLoading
        startAsync={init}
        onFinish={() => {
          setFontLoaded(true);
        }}
        onError={(e) => {
          console.log(e)
        }}
      />)
  } else {
    return (
      <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding' : 'height'} style={styles.container}>
          <View style={styles.content}>
          {
            user && project? (
              <SafeAreaView style={styles.content}>
                <Header></Header>
                <NavigationHolder></NavigationHolder>
              </SafeAreaView>
            ): (
              <LoginScreen></LoginScreen>
            )
          }
          </View>
          {
            Platform.OS != 'web'? (
              <StatusBar barStyle={'light-content'} />
            ): null
          }
      </KeyboardAvoidingView>
    );
  }
}

export default function AppWrapper() {
  return (
    <Provider store={Store}>
      <App /> 
    </Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:Colors.General.SubBg,
    alignItems:'center'
  },
  content: {
    backgroundColor: Colors.General.MainBG,
    width:'100%',
    flex: 1,
    maxWidth:Layout.Settings.DefaultMaxWidth,
    alignSelf:'center',
    justifyContent:'space-between',
    shadowColor:Colors.General.Tab,
    shadowOffset: {
      width:0,
      height:0
    },
    shadowOpacity:0.8,
    shadowRadius:3.5,
    elevation:5
  }
});

import React from 'react';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import Colors from '../constants/Colors';
import FadeInView from '../tools/FadeInView';
import TextField from '../tools/TextField';

export default function UserItem(props) {

    if (props.user.avatar) {
        return (
            <TouchableOpacity activeOpacity={0.5} onPress={() => {props.onSelect(props.user)}}>
                <FadeInView delay={0.2}>
                    <View style={[styles.container, props.last? styles.last: null]}>
                        <Image source={{uri:props.user.avatar}} style={styles.logo}></Image>
                        <TextField text={props.user.name} style={styles.name}></TextField>
                    </View>
                </FadeInView>
            </TouchableOpacity>
        );
        } else return null
}

const styles = StyleSheet.create({
    container: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        margin:5,
        paddingBottom:10,
        position:'relative',
        borderBottomColor:'rgba(255,255,255,0.1)',
        borderBottomWidth:0.5,
    },
    last: {
        borderBottomWidth: 0,
        paddingBottom:0
    },
    name: {
        color: Colors.General.Text1,
        fontSize:18
    },
    logo: {
        width:50,
        height:50,
        borderRadius:10,
        borderWidth:1,
        borderColor: '#ddd',
        marginEnd:10
    }
})
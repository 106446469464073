import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import TextField from '../tools/TextField';
import defaultLogo from '../assets/images/shows/hapanel_final.jpg';
import DateFilter from '../tools/DateFilter';
import Icon from '../constants/Icon';

export default function ChatMessage(props) {
    const [displayDay, setDisplayDay] = useState(false);
    const [displayAuthor, setDisplayAuthor] = useState(false);

    const isSameDay = () => {
        if (!props.prev)
            return false;

        var dPrev = new Date(props.prev.createdAt);
        var dCurrent = new Date(props.message.createdAt);
        if (dPrev.getFullYear() == dCurrent.getFullYear() &&
            dPrev.getMonth() == dCurrent.getMonth() &&
            dPrev.getDate() == dCurrent.getDate())
                return true;

        return false;
    }
    const isSameAuthor = () => {
        if (!props.prev)
            return false;
        
        if (props.prev.user.id == props.message.user.id)
            return true;
        
        return false;
    }

    if (props.message) {
        useEffect(() => {
            const sameDay = isSameDay();
            const sameAuthor = isSameAuthor();
            setDisplayDay(!sameDay);
            setDisplayAuthor(!sameDay || !sameAuthor);
        }, []);

        return (
            <View>
                {
                    displayDay? (
                        <View style={styles.dayHolder}>
                            <TextField text={DateFilter.MessageTime(props.message.createdAt)} style={styles.day}></TextField>
                        </View>
                    ): null
                }
                <View style={[styles.container, !displayAuthor?styles.containerNoMargin:null]}>
                    {
                        displayAuthor? (
                            props.message.user.id == 0? (
                                <Image source={defaultLogo} style={[styles.avatar, styles.avatarDefault]}></Image>
                            ): (
                                <Image defaultSource={defaultLogo} source={{uri:props.message.user.avatar}} style={styles.avatar}></Image>
                            )
                        ): null
                    }
                    <View style={styles.content}>
                        
                        {
                            displayAuthor? (
                                <TextField text={props.message.user.name} style={styles.author} weight={700}></TextField>
                            ): null
                        }
                        
                        <TextField text={props.message.text} style={styles.text}></TextField>
                        <View style={{flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                        <TextField text={DateFilter.Time(props.message.createdAt)} style={styles.time}></TextField>
                            {
                                props.onDelete != null? (
                                    <TouchableOpacity onPress={() => {props.onDelete(props.message)}}>
                                        <Icon type={Icon.Type.Delete} style={styles.deleteIcon}></Icon>
                                    </TouchableOpacity>
                                ): null
                            }
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    else return null;
}
const avatarSize = 50;
const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.Chat.MessageBg,
        margin:Layout.Settings.MarginNormal,
        marginStart: avatarSize / 4 + Layout.Settings.MarginNormal,
        borderRadius:5,
        flexDirection:'row',
    },
    containerNoMargin: {
        marginTop:0
    },
    dayHolder: {
        alignItems:'center',
        marginTop:Layout.Settings.MarginNormal,
    },
    day: {
        color: Colors.Chat.MessageBg
    },
    avatar: {
        width:avatarSize,
        height:avatarSize,
        borderRadius:avatarSize,
        margin:-avatarSize/4,
        borderColor:Colors.Chat.AuthorBorder,
        borderWidth:1
    },
    avatarDefault: {
        
    },
    content: {
        padding:Layout.Settings.MarginSmall,
        paddingStart:avatarSize/4 + Layout.Settings.MarginSmall,
        width: 0,
        flexGrow: 1,
        position:'relative'
    },
    author: {
        color:Colors.Chat.Author,
        fontSize:14
    },
    text: {
        color:Colors.Chat.MessageText,
        fontSize:18
    },
    time: {
        alignSelf:'flex-end',
        color:Colors.Chat.Author,
        fontSize:14
    },
    deleteIcon: {
        color:Colors.General.SubBg,
        fontSize:26,
        marginTop:5
    }
});

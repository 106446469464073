import { Alert, Platform } from 'react-native';
import Strings from '../constants/Strings';

export default class AlertingService {
    static Prompt = (title, description, callback) => {
        if (Platform.OS === 'web') {
            const res = window.confirm(title + "\n" + description);
            if (res && callback)
                callback()
        } else {
            Alert.alert(
                title,
                description,
                [
                    {
                    text: Strings.General.Cancel,
                    style: "cancel"
                    },
                    { text: Strings.Push.AlertOK, onPress: (() => {
                        if (callback) callback()
                    }) }
                ],
                { cancelable: false }
            );
        }
    }
    static Alert = (title, description, callback) => {
        if (Platform.OS === 'web') {
            window.alert(description)
            if (callback)
                callback()
        } else {
            Alert.alert(
                title,
                description,
                [
                    {
                    text: Strings.General.Thanks,
                    style: "cancel"
                    }
                ],
                { cancelable: false }
            );
        }
    }
}
import Strings from '../constants/Strings';
import AlertingService from './AlertingService';
import Fire from './Fire';
import HttpService from './HttpService';

export const SEGMENTS = [
    {
        key: 'Subscribed Users',
        name: 'לכל היוזרים'
    },
    {
        key: 'Active Users',
        name: 'ליוזרים פעילים'
    },
    {
        key: 'Inactive Users',
        name: 'ליוזרים לא פעילים'
    },
    {
        key: 'Test Users',
        name: 'טסט'
    }
]

const API = 'https://onesignal.com/api/v1/notifications'

export default class PushService {
    static Send = async (project, data) => {
        const result = await fetch(API, {
            method: 'POST',
            headers: new Headers({
                'Authorization': `Basic ${project.signal.auth}`,
                'Content-Type': 'application/json; charset=utf-8'
            }),
            body: JSON.stringify({
                app_id: project.signal.key,
                included_segments: [data.segment],
                priority: 10,
                android_visibility: 1,
                ...data
            })
        })
        const res = await result.json()
        // data.senderId = Fire.shared.uid
        
        // if (res.status > 200) {
        //     AlertingService.Alert(Strings.Push.AlertTitle, Strings.Push.Error)
        //     return null
        // }
        const fb_data = {
            project: project.id, 
            title: data.headings.en, 
            body: data.contents.en, 
            data: data.data
        }
        if (!__DEV__) {
            try {
                setTimeout(() => {
                    HttpService.Post("push", fb_data)
                }, 500)
            } catch (e) {
                console.log(e)
            }
        }
                
        // WITH DEVICES
        // AlertingService.Alert(Strings.Push.AlertTitle, Strings.Push.Successful + '-' + res.recipients + ' ' + Strings.Push.Devices)
        AlertingService.Alert(Strings.Push.AlertTitle, Strings.Push.Successful)
        // const pushData = await res.json()
        // console.log(pushData)
        return true
    }

    static Get = async (project) => {
        const result = await fetch(`${API}?app_id=${project.signal.key}&limit=50`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Basic ${project.signal.auth}`,
                'Content-Type': 'application/json; charset=utf-8'
            })
        })
        const data = await result.json()
        const pushes = []
        data.notifications.forEach(p => {
            if (!__DEV__ && p.included_segments.indexOf("Test Users") > -1) return
            const stats = p.platform_delivery_stats
            let success = 0
            let failed = 0
            let converted = 0
            if (stats.ios) {
                success += stats.ios.successful || 0
                failed += (stats.ios.failed || 0) + (stats.ios.errored || 0)
                converted += stats.ios.converted || 0
            }
            if (stats.android) {
                success += stats.android.successful || 0
                failed += (stats.android.failed || 0) + (stats.android.errored || 0)
                converted += stats.android.converted || 0
            }
            let segment = SEGMENTS[0]
            if (p.included_segments?.length > 0)
                segment = SEGMENTS.find(s => s.key === p.included_segments[0]) || SEGMENTS[0]
            pushes.push({
                title: p.headings.en,
                body: p.contents.en,
                logo: p.big_picture,
                date: new Date(p.completed_at * 1000),
                count: {
                    sentTo: success + failed,
                    failed: failed,
                    converted: converted
                },
                segment: segment
            })
        });
        return pushes
    }
}
const General = {
    MainBG: '#181C27',
    Tab: '#141823',
    SubBg: '#2A303C',
    Contrast: '#3F5970',
    Text1: 'rgba(255,255,255,1)',
    Text2: 'rgba(255,255,255,0.8)',
    Text3: 'rgba(255,255,255,0.5)',
    Link: '#9FCA01',
    Transparent: 'rgba(0,0,0,0)',
    Shadow: 'rgba(255,255,255,1)',
    Button: '#1B97F3'
};
const Header = {
    PlayBorder: '#fff',
    Shadow: '#000'
}
const Chat = {
    MessageBg: 'rgba(255,255,255,0.9)',
    MessageText: General.MainBG,
    Time: General.Text2,
    Name: General.Text1,
    Author: General.MainBG,
    AuthorBorder: 'rgba(0,0,0,0.2)',
    AuthorShadow: 'rgba(0,0,0,0.4)',
}
const Slider = {
    Main: General.Text2,
    Sub: General.Link
}
const Skeleton = {
    BG: General.SubBg,
    Row: 'hsl(200, 20%, 70%)',
    RowDark: 'hsl(200, 80%, 100%)'
}
export default {
    General,Header,Chat,Slider,Skeleton
};

import {createStore, combineReducers} from 'redux'

// ************
// ACTIONS
// ************

const SetProject = project => {
    return {type: 'SET_PROJECT', payload: project}
}
const SetUser = user => {
    return {type: 'SET_USER', payload: user}
}
const Logout = () => {
    return {type: 'LOGOUT'}
}
const SetPushes = pushes => {
    return {type: 'SET_PUSHES', payload: pushes}
}
const AddPush = push => {
    return {type: 'ADD_PUSH', payload: push}
}

// ************
// REDUCERS
// ************
const userReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_USER': 
            return action.payload
        case 'LOGOUT':
            return null
        default:
            return state? {...state}: null
    }
}
const projectReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_PROJECT':
            delete action.payload.config
            return action.payload
        default: 
            return state? {...state}: null
    }
}
const pushesReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_PUSHES':
            return [...action.payload]
        case 'ADD_PUSH':
            return [action.payload, ...state]
        default: 
            return [...state]
    }
}

// ************
// EXPORT
// ************

const allReducers = combineReducers({
    project: projectReducer,
    user: userReducer,
    pushes: pushesReducer
})

const Store = createStore(allReducers);
export {Store, SetProject, SetUser, Logout, SetPushes, AddPush};
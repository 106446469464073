import { useCallback } from 'react'

export default function useBitly() {
    const bitly = useCallback(async (url) => {
        try {
            let res = await fetch("https://api-ssl.bitly.com/v4/shorten", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer e88a0fddf524d43ed4daf791f52624cd8cd65380'
                },
                body: JSON.stringify({ "long_url": url })
            })
            const link = await res.json()
            return link.link
        } catch (e) {
            console.error(e)
        }
    }, [])

    return bitly
}

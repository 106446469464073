import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import TextField from '../tools/TextField';
import FadeInView from '../tools/FadeInView';
import DateFilter from '../tools/DateFilter';
import defaultLogo from '../assets/images/icon.jpg'

export default function EpisodeItem(props) {
  
    const select = () => {
      props.onSelect(props.episode);
    }

    if (props.episode) {
      return (
        <View>
          <TouchableOpacity activeOpacity={0.5} onPress={select}>
            <FadeInView delay={0.2}>
              <View style={styles.container}>
                <View style={styles.logoHolder}>
                  <Image defaultSource={defaultLogo} source={{uri:props.episode.logo}} style={styles.logo}></Image>
                </View>
                <View style={styles.content}>
                  <TextField text={props.episode.title} style={styles.title} weight={600} lines={2}></TextField>
                  <TextField text={props.episode.description} style={styles.description} weight={200} lines={2}></TextField>
                </View>
              </View>
            </FadeInView>
          </TouchableOpacity>
        </View>
        );
    }
  else return null;
}

const styles = StyleSheet.create({
  container: {
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'flex-start',
    margin:Layout.Settings.MarginSmall,
    paddingBottom:Layout.Settings.MarginNormal,
    position:'relative',
    borderBottomColor:'rgba(255,255,255,0.1)',
    borderBottomWidth:0.5,
  },
  content: {
    width: 0,
    flexGrow: 1,
  },
  logoHolder: {
    position:'relative',
    overflow:'hidden',
    marginEnd:Layout.Settings.MarginNormal,
    borderRadius:Layout.Settings.BorderRadius,
    width:120,
    height:110,
  },
  logo: {
    width:'100%',
    height:'100%',
    borderRadius:Layout.Settings.BorderRadius,
  },
  title: {
    fontSize: 16,
    width:'100%',
    color:Colors.General.Text2
  },
  description: {
    fontSize: 16,
    width:'100%',
    color:Colors.General.Text3,
    marginVertical:Layout.Settings.MarginSmall,
  },
  time: {
    fontSize:13,
    color:Colors.General.Text2,
    alignSelf:'flex-end'
  },
  mediaAndDate: {
    flexDirection:'row',
    justifyContent:'flex-end',
    alignItems:'center',
  }
});

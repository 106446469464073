import React, {useState, useEffect} from 'react';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import Colors from '../constants/Colors';
import Strings from '../constants/Strings';
import Icon from '../constants/Icon';
import TextField from '../tools/TextField';
import Enums from '../constants/Enums';
import Constants from 'expo-constants';
import {useSelector} from 'react-redux'

export default function HomeScreen(props) {
  const user = useSelector(state => state.user)
  const [version, setVersion] = useState(null);
  const project = useSelector(state => state.project)

  
  useEffect(() => {
    if (Platform.OS == 'web') {
      setVersion({
        version: Constants.deviceName,
        build: Constants.manifest.version
      })
    } else {
      setVersion({
        version: Constants.nativeAppVersion,
        build: Constants.nativeBuildVersion
      });
    }
    
  }, []);
  
  return (
      <View style={styles.container}>
        <View style={styles.menu}>
          {
            project?.pages.findIndex(p => p.en === 'Chat') > -1? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Chat")}}>
                <Icon type={Icon.Type.Chat} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Chat} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          }
          
          {
            project?.pages.findIndex(p => p.en === 'Push') > -1 && user?.rank == Enums.UserRank.Admin? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Push")}}>
                <Icon type={Icon.Type.Notification} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Push} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          }
          {
            project?.pages.findIndex(p => p.en === 'Popup') > -1 && user?.rank == Enums.UserRank.Admin? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Popup")}}>
                <Icon type={Icon.Type.Popup} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Popup} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          }
          {
            project?.pages.findIndex(p => p.en === 'Businesses') > -1 && user?.rank == Enums.UserRank.Admin? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Businesses")}}>
                <Icon type={Icon.Type.Businesses} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Businesses} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          }
          {
            project?.pages.findIndex(p => p.en === 'Links') > -1? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Links")}}>
                <Icon type={Icon.Type.Share} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Links} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          }
          {/* {
            user && user.rank == Enums.UserRank.Admin? (
              <TouchableOpacity style={styles.iconHolder} onPress={() =>{props.navigation.navigate("Settings")}}>
                <Icon type={Icon.Type.Settings} style={styles.icon}></Icon>
                <TextField text={Strings.Menu.Settings} style={styles.text}></TextField>
              </TouchableOpacity>
            ): null
          } */}
        </View>
        {
          version && version.version && version.build? (
            <View style={styles.version}>
              <TextField text={Strings.General.Version} style={{color:Colors.General.Text3}}></TextField>
              <TextField text={version.version} style={{color:Colors.General.Text1, marginHorizontal:5}}></TextField>
              <TextField text={"(" + version.build + ")"} style={{color:Colors.General.Text3}}></TextField>
            </View>
          ): null
        }
      </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.General.SubBg,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menu: {
    flexDirection:'row',
    alignItems:'center',
    flexWrap:'wrap'
  },
  iconHolder: {
    alignItems:'center',
    justifyContent:'center',
    width:100,
    height:100,
  },
  icon: {
    fontSize:40,
    marginBottom:10
  },
  text: {
    fontSize:16
  },
  version: { 
    marginVertical: 50,
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row'
  }
});
